import React from 'react'; // required in order to use JSX
import VisibilitySensor from 'react-visibility-sensor';
import moment from 'moment';
import numeral from 'numeral';
const uuidv1 = require('uuid/v1');


class ActivityRing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            seenOnce: false
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(isVisible) {
        if (isVisible) {
            this.setState({
                seenOnce: true
            });
        }
    }

    circumference(radius) {
        return 2 * Math.PI * radius;
    }

    render() {

        let energypx = this.circumference(90);
        let exercisepx = this.circumference(70);
        let standpx = this.circumference(50);

        if (this.state.seenOnce) {
            energypx = (this.props.energyProgress > 1 ? 0 : (this.circumference(90) - (this.props.energyProgress * this.circumference(90))));
            exercisepx = (this.props.exerciseProgress > 1 ? 0 : (this.circumference(70) - (this.props.exerciseProgress * this.circumference(70))));
            standpx = (this.props.standProgress > 1 ? 0 : (this.circumference(50) - (this.props.standProgress * this.circumference(50))));
        }

        let tooltip = `${moment(this.props.DATE, 'YYYY-MM-DD').format('dddd, MMMM Do')}: ${numeral(this.props.energy).format('0,0')}kJ energy, ${numeral(this.props.exercise/60).format('0,0')}min exercise, ${numeral(this.props.stand).format('0,0')}hr stand`

        return (
            <VisibilitySensor onChange={this.onChange}>
                <div style={this.props.style} title={tooltip}>
                    <svg viewBox="0 0 200 200" width="40" className="activity-dial"  >
                        <g className="background">
                            <circle className="bg-1" cx="100" cy="100" />
                        </g>
                        <g className="path">
                            <circle className="inactive-1" cx="100" cy="100" />
                            <circle className="inactive-2" cx="100" cy="100" />
                            <circle className="inactive-3" cx="100" cy="100" />
                        </g>
                        <g className="rings">
                            <circle className="circle-1" cx="100" cy="100" style={{ strokeDashoffset: energypx }} />
                            <circle className="circle-2" cx="100" cy="100" style={{ strokeDashoffset: exercisepx }} />
                            <circle className="circle-3" cx="100" cy="100" style={{ strokeDashoffset: standpx }} />
                        </g>
                    </svg>
                </div>
            </VisibilitySensor>
        );
    }

};

export { ActivityRing };

class ActivityRingCollection extends React.Component {

    render() {
        const collection = this.props.days;

        // console.log(collection.length);

        var currentKey = uuidv1();

        let rings = [];
        for (let index = 0; index < collection.length; index++) {
            rings.push(<ActivityRing {...collection[index]} style={{ width: '40px', margin: '5px', height: '40px' }} key={`rings_${currentKey}_${index}`} />);
        }

        return (
            <div>
                <div className="container">
                    <div className="number-context-title heatmap-title">{this.props.title}</div>
                </div>
                <div className="activity-dial-container fix-scroll">
                    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', height: 'calc(7 * (40px + 5px + 5px))' }}>{rings}</div>
                </div>
            </div>
        );
    }

};

export { ActivityRingCollection };

