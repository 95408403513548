import React from "react"; // required in order to use JSX
import uuidv1 from "uuid/v1";
import ReactMarkdown from 'react-markdown';

export class InfoBox extends React.Component {
  render() {
    return (
        <div className="box">
          <div>
            <p style={{ marginBottom: 0 }}>
              {this.props.title} {this.props.index}
            </p>
            <h1>{this.props.headline}</h1>
          </div>
          <div>
            <hr className="my-2" />
            <ReactMarkdown className="lead" source={this.props.subtitle} />
            <ReactMarkdown source={this.props.body} />
          </div>
        </div>
    );
  }
}

export class InfoBoxes extends React.Component {
  render() {
    var boxes = [];
    for (let index = 0; index < this.props.boxes.length; index++) {
      const element = this.props.boxes[index];
      const propsobj = {
        title: this.props.title,
        index: index + 1,
        headline: element[this.props.headline],
        subtitle: this.props.subtitleString(element),
        body: this.props.bodyString(element)
      };
      boxes.push(<InfoBox {...propsobj} key={uuidv1()} />);
    }

    return <div className="section"><div className="infoBoxes">{boxes}</div></div>;
  }
}
