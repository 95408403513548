import React from "react"; // required in order to use JSX

export class Embed extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      windowHeight: undefined,
      windowWidth: undefined
    }
    this.handleResize = this.handleResize.bind(this);
  }

  handleResize() {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  render() {
    return (
      <div className="container embed" style={{ marginTop: "2em", display: "flex", justifyContent: "center" }}>

        {this.state.windowWidth > 550 ?

          <div style={{ width: "100%" }}>
            <iframe {...this.props.iframe} title="iframe" style={{ width: "100%", margin: "auto" }} />
          </div>

          :

          <div style={{ marginTop: "5em"}}>
              <a style={{  display: "block",textDecoration: "none", padding: "0.5em", border: "1px solid var(--accent-color)", borderRadius: "5px" }} href={this.props.iframe.src} target="_blank" rel="noopener noreferrer">View My Top 10 Most Played Songs</a>
          </div>

        }



      </div>
    );
  }
}
