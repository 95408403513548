import React from 'react'; // required in order to use JSX
import ScrollableAnchor from 'react-scrollable-anchor';
const ReactMarkdown = require('react-markdown');

export const Asside = (props) => {
    return (
        <div className="container">
            <div className="asside-paragraph">
                <ScrollableAnchor id={props.title.replace(/\s/g,'-')}>
                    <h2>{props.title}</h2>
                </ScrollableAnchor>
                <ReactMarkdown disallowedTypes={['image']} skipHtml={true} source={props.content} />
                <hr />
            </div>
        </div>
    );
};

export default Asside;

