import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

export class Gallery extends React.Component {
  render() {
    var thumbs = [];
    const urls = this.props.thumbs.sort(function(a, b) {
      return 0.5 - Math.random();
    });
    for (let index = 0; index < urls.length; index++) {
      const element = urls[index];
      thumbs.push(
          // eslint-disable-next-line
        <img className="thumb" src={"images/" + element} key={element} />
      );
    }

    return <div className="thumnails">{thumbs}</div>;
  }
}

export class Slider extends React.Component {
  render() {
    return (
      <div className="container" style={{maxWidth: "700px"}}>
        <ImageGallery
          items={this.props.items}
          lazyLoad={true}
          showFullscreenButton={false}
          showPlayButton={false}
          autoPlay={true}
          slideInterval={6000}
          
        />
        <div style={{ marginTop: "5em" }}>
          <a
            style={{
              display: "block",
              textDecoration: "none",
              padding: "0.5em",
              border: "1px solid var(--accent-color)",
              borderRadius: "5px",
              textAlign: "center"
            }}
            href="/map"
            target="_blank"
          >
            View the Interactive Map
          </a>
        </div>
      </div>
    );
  }
}
