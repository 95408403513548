import React from 'react'; // required to use JSX
import Number from './Number';


export const NumberGroup = (props) => {

    // get references to all possible components
    // that this component might render,
    // and the collection of dynamic components we need to render,
    // using destructuring
    const collection = props.collection;


    let numbers = [];
    for (let index = 0; index < collection.length; index++) {
        let component = collection[index];
        numbers.push(<Number {...component} />);
    }

    // render the component collection
    return (
        <div className="container">
            <div className="numberGroup">{numbers}</div>
        </div>
    );
};

export default NumberGroup;
