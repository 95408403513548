import React from "react"; // required in order to use JSX
import * as d3 from "d3";
// import * as topojson from "topojson";
import LatLon from "geodesy/latlon-spherical";
import { geoEckert3 } from "d3-geo-projection";
// import VisibilitySensor from "react-visibility-sensor";

export class SlopesMapDetail extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const el = this.ref.current;

    // console.log(el);

    const ctx = el.getContext("2d");

    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    ctx.fillStyle = "#ffffff";

    const that = this;

    d3.csv(this.props.url).then(function(loadedCSVResults) {
      // console.log("hmmm", loadedCSVResults[0].Longitude);
      const sums = loadedCSVResults.reduce(function(a, b) {
        return {
          Longitude: Number(a.Longitude) + Number(b.Longitude),
          Latitude: Number(a.Latitude) + Number(b.Latitude)
        };
      });

      const avg = {
        Longitude: sums.Longitude / loadedCSVResults.length,
        Latitude: sums.Latitude / loadedCSVResults.length
      };

      const projection = geoEckert3()
        .center([avg.Longitude, avg.Latitude])
        .scale(that.props.scale)
        .translate([1000, 500]);
      // .rotate([-200, 0]);
      const geoPathGenerator = d3
        .geoPath()
        .projection(projection)
        .context(ctx);

      var lineStringGeometryInfos = loadedCSVResults.map(function(
        csvRow,
        index,
        array
      ) {
        var fromCoordinates = [csvRow.Longitude, csvRow.Latitude];

        var toCoordinates = fromCoordinates;
        if (array[index + 1]) {
          toCoordinates = [
            array[index + 1].Longitude,
            array[index + 1].Latitude
          ];
        }

        var lineStringGeometry = {
          type: "LineString",
          coordinates: [fromCoordinates, toCoordinates]
        };

        var fromLatLon = new LatLon(fromCoordinates[1], fromCoordinates[0]);
        var toLatLon = new LatLon(toCoordinates[1], toCoordinates[0]);
        var midLatLon = fromLatLon.midpointTo(toLatLon);

        return {
          lineStringGeometry: lineStringGeometry,
          centroid: d3.geoCentroid(lineStringGeometry),
          bearing: midLatLon.bearingTo(toLatLon),
          fromCoordinates: fromCoordinates,
          toCoordinates: toCoordinates
        };
      });

      d3.select({})
        .transition()
        .duration(500)
        .tween(null, function() {
          return function(t) {
            // progressively draw partial flight lines during each step of the transition
            ctx.lineWidth = 3;
            ctx.strokeStyle = "black";
            lineStringGeometryInfos.forEach(function(geometryInfo) {
              var partialLine = JSON.parse(
                JSON.stringify(geometryInfo.lineStringGeometry)
              );
              var interpolatedEndPoint = d3.geoInterpolate(
                partialLine.coordinates[0],
                partialLine.coordinates[1]
              )(t);
              partialLine.coordinates[1] = interpolatedEndPoint;
              ctx.beginPath();
              geoPathGenerator(partialLine);
              ctx.stroke();
              ctx.closePath();
            });
          };
        });
    });
  }

  render() {
    return (
      <canvas
        height={1200}
        width={1960}
        ref={this.ref}
        style={{
          width: "100%",
          height: "auto"
        }}
      />
    );
  }
}

export class SlopesMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seenOnce: false
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(isVisible) {
    if (isVisible) {
      this.setState({
        seenOnce: true
      });
    }
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="number-context-title heatmap-title">
            {this.props.title}
          </div>
        </div>
        <SlopesMapDetail {...this.props} />
      </div>
    );
  }
}

export default SlopesMap;
