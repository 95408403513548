import React from "react";
import { Sparklines, SparklinesCurve } from 'react-sparklines';


export class Sparkline extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="number-context-title heatmap-title">{this.props.title}</div>
        <Sparklines style={{ width: "100%", maxHeight: '60px' }} data={this.props.data}>
          <SparklinesCurve
            style={{ fill: "none" , stroke: this.props.color ? this.props.color : "#329fff", strokeWidth: "1"}}
            margin={1}
          />
        </Sparklines>
      </div>
    );
  }
}
