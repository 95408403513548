import React from 'react'; // required to use JSX



export const CapitalizedReferenceComponentCollectionExternals = (props) => {

  // get references to all possible components
  // that this component might render,
  // and the collection of dynamic components we need to render,
  // using destructuring
  const collection = props.collection;
  const elements = props.elements;


  // A Capitalized reference to reuse
  let Component;

  let output = [];
  for (let index = 0; index < collection.length; index++) {
    let component = collection[index];
    Component = elements[component.type];
    output.push(<Component {...component} />);
  }

  // render the component collection
  return (
    output
  );
};

export default CapitalizedReferenceComponentCollectionExternals;
