import React from "react"; // required in order to use JSX
import uuidv1 from "uuid/v1";
import ReactStateAnimation from 'react-state-animation';


function r(
  min,
  max // min and max included
) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}


export class BlobCircle extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ani: 0,
      cx: r(50, 300),
      cy: r(50, 300),
      r: (props.big ? r(20, 60) : r(10, 20)),
      visible: false
    }
    // react state animation wrapper
    this._animate = new ReactStateAnimation(this);
  }

 
  start() {
    // start animation
    var that = this;
    that._animate.linearInOut('ani', r(0, 90)/*end value*/, 7000/*duration(ms)*/).then(function () {
      that.start();
    });
  }

  componentDidMount() {
    this.start();
  }

  stop() {
    this._animate.stop();
  }

  getTransform() {
    return (`rotate(${this.state.ani}) translate(${this.state.ani}, 0.1) rotate(${-this.state.ani})`);
  }

  render() {
    return (
        <circle className="blob" cx={this.state.cx} cy={this.state.cy} r={this.state.r} transform={this.getTransform()} />
    );
  }

}


export class BigHeader extends React.Component {

  generateRandomBlobs() {
    var blobs = Array(r(10,30)).fill().map(function () {
      return (<BlobCircle className="blob" key={uuidv1()} />)
    })
    blobs.push(<BlobCircle className="blob" key={uuidv1()} big={true} />)
    return blobs;
  }


  render() {
    return (
      <div className="container bigtitle" style={{ height: "90vh" }}>
        <div className="loading_cont">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="loader" width="400" height="400" viewBox="0 0 400 400">
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                <feBlend in2="goo" in="SourceGraphic" result="mix" />
              </filter>
              <linearGradient id="MyGradient">
                <stop offset="5%" stopColor="var(--accent-color)" />
                <stop offset="40%" stopColor="var(--accent-color-2)" />
                <stop offset="100%" stopColor="var(--accent-color)" />
              </linearGradient>
            </defs>
            <mask id="maska">
              <g className="blobs">
                {this.generateRandomBlobs()}
              </g>
            </mask>
            <rect x="0" y="0" mask="url(#maska)" fill="url(#MyGradient)" width="400" height="400" />
          </svg>
        </div>
        <div className="bigTitle">
          <h1>{this.props.title}</h1>
          <h2>{this.props.subtitle}</h2>
        </div>
      </div>
    );
  }
}

export class Footer extends React.Component {
  render() {
    return(
      <div className="section footer" style={{justifyContent: "center"}}>
        <div style={{textAlign: "center", textDecoration: "none", color: "white"}}><a href="https://patmurray.co/tip/">
          <h2 style={{margin: "0", padding: "0"}}>Created By</h2>
          <h1 style={{margin: "0", padding: "0"}}>Pat Murray</h1>
          </a>
        </div>
      </div>
    )
  }
}

export default BigHeader;
