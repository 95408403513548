import React from 'react'; // required in order to use JSX
const ReactMarkdown = require('react-markdown')

export const Passage = (props) => {

  return (<div className="container">
    <div className="asside-paragraph paragrah-normal">
      <ReactMarkdown source={props.content} />
      <hr />
    </div>
  </div>);

};

export default Passage;

