import React from 'react'; // required in order to use JSX
import numeral from 'numeral';

export const StoryNumber = (props) => {
    return(
        <span className="story-number">{numeral(props.n).format('0,0')}&nbsp;<span className="story-number-unit"><span className="story-number-unit-item numeral">{props.u}</span></span></span>
    );
}

export const Bold = (props) => {
    return(
        <span className="bold">{props.children}</span>
    );
}

export const Story = (props) => {

  return (<div className="container">
    <div className="story">
      In 2018 I <Bold>walked</Bold> <StoryNumber n={2126} u={'km'} />&nbsp;
      across <StoryNumber n={2488122} u={'steps'} />.<br/><br/>
      I closed my <Bold>activity</Bold> rings <StoryNumber n={174} u={'times'} />, 
      with a max <Bold>heartrate</Bold> of <StoryNumber n={191} u={'bpm'} />. <br/><br/>
      I spent <StoryNumber n={3902} u={'min'} /> in the air, <Bold>travelling</Bold> <StoryNumber n={54859} u={'km'} />, 
      on <StoryNumber n={10} u={'flights'} />. <br/><br/>
      I skied <StoryNumber n={46648} u={'m'} /> vertical, 
      topping out at <StoryNumber n={112} u={'km/h'} />. <br/><br/>
      I listened to <StoryNumber n={47050} u={'min'} /> of music, 
      accross <StoryNumber n={4856} u={'songs'} />&nbsp;
      and <StoryNumber n={1699} u={'artists'} />. <br/><br/>
      I also spent <StoryNumber n={1459} u={'min'} /> on computers, 
      and took <StoryNumber n={13264} u={'photos'} />. <br/><br/><br/>
      2018 was full of highs and lows, but it was certainly an adventure. <br/>
      <Bold>This, is my year in numbers&hellip;</Bold>
      
    </div>
  </div>);

};

export default Story;

