import ReactMapboxGl, { ZoomControl } from "react-mapbox-gl";
import React from "react"; // required in order to use JSX

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoicGF0bXVycmF5IiwiYSI6ImNqc2Z2NWl5djFsYjQ0NG9heGozcnd0MWkifQ.xtxsJ1vFUMrviUSQjpKk6Q"
});

export class MapBox extends React.Component {
  render() {
    return (
      <div>
        <Map
          style={this.props.url}
          zoom={[4]}
          center={[151.20699, -33.867487]}
          scrollZoom={false}
          containerStyle={{
            height: "90vh",
            width: "90vw",
            margin: "auto"
          }}
        >
          <ZoomControl />

        </Map>
      </div>
    );
  }
}

export default MapBox;
