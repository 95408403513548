import React from 'react';


export class CompareTwo extends React.Component {
    render() {
        const props = this.props;
        return (
            <div className="compareTwo" style={{ display: "grid", gridTemplateColumns: "80px auto 80px" }}>
                <div className={((props.leftCount > props.rightCount ? "word winner" : "word"))}>{props.left}</div>
                <div className="meter"><span className="midway"></span><span className="left" style={{ flexGrow: props.rightCount }}></span><span className="carrot"></span><span className="right" style={{ flexGrow: props.leftCount }}></span></div>
                <div className={((props.leftCount > props.rightCount ? "word" : "word winner"))}>{props.right}</div>

            </div>
        )
    }
}

export class CompareTwoList extends React.Component {
    render() {
        var elements = []
        var components = this.props.components.sort((a, b) => parseFloat(Math.max(b.leftCount, b.rightCount)) - parseFloat(Math.max(a.leftCount, a.rightCount)));
        for (let index = 0; index < components.length; index++) {
            const element = components[index];
            elements.push(<CompareTwo {...element} key={`comparetwo_${element.left}${element.leftCount}_${element.right}${element.rightCount}`} />)
        }
        return (
            <div className="section" >
                <div className="container CompareTwoList" style={{marginBottom: "3em"}}>
                    {elements}
                </div>
            </div>

        )
    }
}

export default CompareTwoList;