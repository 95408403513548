import React, { Component } from 'react';
import CapitalizedReferenceComponentCollectionExternals from './items/CapitalizedReferenceComponentCollectionExternals';
import * as elements from './items';
import Page from 'react-page-loading'



const testComponentConfiguration = require('./Data');

class App extends Component {
  render() {
    return (
      <Page loader={"bubble"} color={"var(--accent-color)"} size={10}>
        <CapitalizedReferenceComponentCollectionExternals collection={testComponentConfiguration} elements={elements} />
      </Page>
    );
  }
}

export default App;
