import React from "react"; // required in order to use JSX
import * as d3 from "d3";
import * as topojson from "topojson";
// import { geoMercator, geoOrthographic } from "d3-geo";
import { geoEckert3 } from "d3-geo-projection";
import LatLon from "geodesy/latlon-spherical";
import VisibilitySensor from "react-visibility-sensor";

export class Map extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      currentFlight: ""
    };
  }

  drawInitialLines(ctx, geoPathGenerator) {
    const _this = this;

    Promise.all([d3.csv("flights.csv"), d3.json("airports.topo.json")]).then(
      function(loadedDataResults) {
        var csvData = loadedDataResults[0];

        var airportsGeoJson = topojson.feature(
          loadedDataResults[1],
          loadedDataResults[1].objects.airports
        );

        var lineStringGeometryInfos = csvData.map(function(csvRow) {
          // console.log(csvRow);

          var fromCoordinates = airportsGeoJson.features.filter(function(f) {
            return f.properties.name === csvRow.From;
          })[0].geometry.coordinates;

          var toPlace = airportsGeoJson.features.filter(function(f) {
            return f.properties.name === csvRow.Destination;
          });

          // console.log("toPlace", toPlace);

          var toCoordinates = toPlace[0].geometry.coordinates;

          var lineStringGeometry = {
            type: "LineString",
            coordinates: [fromCoordinates, toCoordinates]
          };

          var fromLatLon = new LatLon(fromCoordinates[1], fromCoordinates[0]);
          var toLatLon = new LatLon(toCoordinates[1], toCoordinates[0]);
          var midLatLon = fromLatLon.midpointTo(toLatLon);

          return {
            lineStringGeometry: lineStringGeometry,
            centroid: d3.geoCentroid(lineStringGeometry),
            bearing: midLatLon.bearingTo(toLatLon),
            fromCoordinates: fromCoordinates,
            toCoordinates: toCoordinates,
            fromAirportName: csvRow.From,
            toAirportName: csvRow.Destination,
            date: csvRow.Date,
            rank: csvRow.Rank
          };
        });

        d3.select({})
          .transition()
          .duration(3000)
          .tween(null, function() {
            return function(t) {
              // progressively draw partial flight lines during each step of the transition
              ctx.lineWidth = 3;
              ctx.strokeStyle = "black";
              lineStringGeometryInfos.forEach(function(geometryInfo) {
                var partialLine = JSON.parse(
                  JSON.stringify(geometryInfo.lineStringGeometry)
                );
                var interpolatedEndPoint = d3.geoInterpolate(
                  partialLine.coordinates[0],
                  partialLine.coordinates[1]
                )(t);
                partialLine.coordinates[1] = interpolatedEndPoint;
                ctx.beginPath();
                geoPathGenerator(partialLine);
                ctx.stroke();
                ctx.closePath();
              });
            };
          })
          .on("end", function() {
            _this.performRecursiveAnimation(
              lineStringGeometryInfos,
              geoPathGenerator,
              ctx
            );
          });
      }
    );
  }

  componentDidMount() {
    const el = this.ref.current;

    // console.log(el);

    const ctx = el.getContext("2d");
    const projection = geoEckert3()
      .translate([el.width / 2, el.height / 2])
      .scale(550)
      .rotate([-200, 0]);
    const geoPathGenerator = d3
      .geoPath()
      .projection(projection)
      .context(ctx);

    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    ctx.fillStyle = "#606060";

    const _this = this;

    d3.json("110m.json").then(function(loadedTopJson) {
      var topology = topojson.presimplify(loadedTopJson);
      topology = topojson.simplify(topology, 0.01);
      var worldGeoJson = topojson.feature(topology, topology.objects.countries);

      ctx.lineWidth = 0.5;
      ctx.strokeStyle = "#444444";
      ctx.beginPath();
      geoPathGenerator(worldGeoJson);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();

      _this.drawInitialLines(ctx, geoPathGenerator);
    });
  }

  drawEarth() {}

  performRecursiveAnimation(lineStringGeometryInfos, geoPathGenerator, ctx) {
    const _this = this;
    _this.animateActiveFlightLine(
      lineStringGeometryInfos,
      function() {
        lineStringGeometryInfos.push(lineStringGeometryInfos.shift());
        _this.performRecursiveAnimation(
          lineStringGeometryInfos,
          geoPathGenerator,
          ctx
        );
      },
      geoPathGenerator,
      ctx
    );
  }

  animateActiveFlightLine(
    lineStringGeometryInfos,
    callback,
    geoPathGenerator,
    ctx
  ) {
    var geometryInfo = lineStringGeometryInfos[0];
    var nextGeoJson = geometryInfo.lineStringGeometry;

    this.setState({
      currentFlight: `${geometryInfo.fromAirportName} — ${
        geometryInfo.toAirportName
      } (${geometryInfo.date})`
    });

    d3.select({})
      .transition()
      .duration(4000)
      .tween(null, function() {
        return function(t) {
          // progressively draw active flight line
          ctx.lineWidth = 2;
          ctx.strokeStyle = "white";
          var partialLine = JSON.parse(JSON.stringify(nextGeoJson));
          var interpolatedEndPoint = d3.geoInterpolate(
            partialLine.coordinates[0],
            partialLine.coordinates[1]
          )(t);
          partialLine.coordinates[1] = interpolatedEndPoint;
          ctx.beginPath();
          geoPathGenerator(partialLine);
          ctx.stroke();
          ctx.closePath();
        };
      })
      .transition()
      .duration(1000)
      .tween(null, function() {
        return function(t) {
          // progressively draw active flight line
          ctx.lineWidth = 3;
          ctx.strokeStyle = "black";
          var partialLine = JSON.parse(JSON.stringify(nextGeoJson));
          var interpolatedEndPoint = d3.geoInterpolate(
            partialLine.coordinates[0],
            partialLine.coordinates[1]
          )(t);
          partialLine.coordinates[1] = interpolatedEndPoint;
          ctx.beginPath();
          geoPathGenerator(partialLine);
          ctx.stroke();
          ctx.closePath();
        };
      })
      .on("end", callback);
  }

  render() {
    return (
      <div>
        <p style={{ position: "absolute", textAlign: "center", width: "100%" }}>
          {this.state.currentFlight ? this.state.currentFlight : ""}
        </p>
        <canvas
          height={1200}
          width={1960}
          ref={this.ref}
          style={{
            width: "100%",
            height: "auto"
          }}
        />
      </div>
    );
  }
}

export class BasicMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seenOnce: false
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(isVisible) {
    if (isVisible) {
      this.setState({
        seenOnce: true
      });
    }
  }

  render() {
    return (
      <div style={{ width: "100%", minHeight: "100vh", position: "relative" }}>
        <div className="sticky-top" style={{ top: 0 }}>
          <VisibilitySensor onChange={this.onChange}>
            {this.state.seenOnce ? (
              <Map />
            ) : (
              <div style={{ width: "100%", height: "200px" }} />
            )}
          </VisibilitySensor>
        </div>
      </div>
    );
  }
}

export default BasicMap;
