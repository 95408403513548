import React from 'react'; // required in order to use JSX
import CountUp from 'react-countup';
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';
import numeral from 'numeral';
const ReactMarkdown = require('react-markdown')

export const Number = (props) => {

    var seenOnce = false;

    function onChange(isVisible) {
        if (isVisible) {
            seenOnce = true;
        }
    }

    return (
        <Fade big cascade fraction={0.7}>
            <div className="number">
                <div className="number-context-title">{props.title} <span className="unit">{props.unit}</span></div>
                <VisibilitySensor onChange={onChange}>
                    {props.noCount ? 
                        <div className="number-digits numeral">{props.prefix?props.prefix:""}{numeral(props.number).format('0,0')}{props.suffix?props.suffix:""}</div>
                    :
                        <CountUp
                            end={seenOnce ? 0 : props.number}
                            separator=","
                            decimal="."
                            prefix={props.prefix}
                            suffix={props.suffix}
                            className="number-digits numeral"
                            duration={2.1}
                            start={0}
                        />
                    }
                    
                </VisibilitySensor>
                <div className="number-context">
                    <div className="number-context-figure"><ReactMarkdown allowedTypes={['text', 'link', 'emphasis', 'paragraph', 'strong']} skipHtml={true} source={props.figuretext} /></div>
                    <div className="number-context-info"><ReactMarkdown allowedTypes={['text', 'link', 'emphasis', 'paragraph', 'strong']} skipHtml={true} source={props.infotext} /></div>
                </div>
            </div>
        </Fade>
    );

};

export default Number;

