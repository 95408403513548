import React from 'react'; // required in order to use JSX
import { Sparklines, SparklinesBars } from 'react-sparklines';

class BubbleMatrix extends React.Component {

    render() {
        const data = this.props.data;
        const flatArray = data.reduce((i, o) => [...o, ...i], []);
        const max = Math.max(...flatArray);
        const min = Math.min(...flatArray);


        let rows = [];
        let sums = Array(data[0].length).fill(0);
        for (let yi = 0; yi < data.length; yi++) {
            let boxes = [];
            for (let xi = 0; xi < data[0].length; xi++) {
                const value = data[yi][xi];
                sums[xi] += value;
                const style_outer = {
                    width: `${100 * ((value - min) / (max - min) || 0)}%`,
                    height: `${100 * ((value - min) / (max - min) || 0)}%`
                }
                const style_inner = {
                    opacity: 50 + (50 * ((value - min) / (max - min) || 0)),
                    background: (this.props.color ? this.props.color : "#329fff"),
                    borderRadius: '50%',
                    width: `100%`,
                    height: `100%`
                }
                boxes.push(<div key={`${xi}_${yi}`} className="matrix-flex-item" title={(this.props.noToolTip ? "" : (value || value === 0) && `${yi}:00 (${value} ${this.props.unit})`)}>
                    <div key={`${xi}_${yi}_circle_outer`} style={style_outer}>
                        <div key={`${xi}_${yi}_circle_inner`} style={style_inner}>
                        </div>
                    </div>
                </div>);
            }
            rows.push(<div key={yi} className="matrix-flex-container">{boxes}</div>)
        }

        let rowTitles = []
        for (let index = 0; index < this.props.row_titles.length; index++) {
            const title = this.props.row_titles[index];
            rowTitles.push(<div key={index + "_title_row"} style={{ margin: '1px', fontSize: '0.5em', fontWeight: '900' }}>{title}</div>)
        }

        let columnTitles = []
        for (let index = 0; index < this.props.column_titles.length; index++) {
            const title = this.props.column_titles[index];
            let borderLeft = (index === 0 ? 'none' : '1px solid rgba(255, 255, 255, .5)');
            columnTitles.push(<div key={index + "_title_column"} style={{ margin: '1px', fontSize: '0.5em', fontWeight: '900', flexGrow: title.columns, textAlign: 'center', borderLeft: borderLeft }}>{title.title}</div>)
        }

        // let sumTitles = []
        // for (let index = 0; index < sums.length; index++) {
        //     const title = sums[index];
        //     sumTitles.push(<div key={index + "_sum_column"} style={{ margin: '1px', fontSize: '0.5em', fontWeight: '900',  textAlign: 'center' }}>{title}</div>)
        // }


        return (
            <div className="section">

            <div className="container" style={{marginBottom: '1em'}}>
                <div className="number-context-title heatmap-title">{this.props.title}</div>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginRight: '20px', height: '60px'}}>
                    <Sparklines style={{ width: '100%' }} data={sums} >
                        <SparklinesBars  style={{ fill: (this.props.color ? this.props.color : "#329fff") }} margin={1} />
                    </Sparklines>
                </div>
                <div className='sticky-top' style={{ display: 'flex', marginRight: '20px'}}>
                    {columnTitles}
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="annual-heatmap" style={{ flexGrow: 1 }}>
                        {rows}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20px', justifyContent: 'space-around' }}>
                        {rowTitles}
                    </div>
                </div>

            </div>
            </div>
        );
    }

};

export { BubbleMatrix };

