import React from 'react'; // required in order to use JSX
import HeatMap from 'react-heatmap-grid';

class Heatmap extends React.Component {

    render() {
        const xLabels = ['AM', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'PM'];
        const xLabelsVisibility = [true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true];
        const yLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
        return (
            <div className="container horizontal_scroll heatmap">
                <div className="number-context-title heatmap-title">{this.props.title}</div>
                <HeatMap
                    squares={false}
                    xLabelsVisibility={xLabelsVisibility}
                    xLabels={xLabels}
                    yLabels={yLabels}
                    data={this.props.data}
                    background={'var(--accent-color)'}
                    unit={this.props.unit}
                    xLabelWidth={100}
                />
            </div>
        );
    }

};

export { Heatmap };

