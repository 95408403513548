const uuidv1 = require("uuid/v1");
const numeral = require("numeral");

function convertTime(timeinmilli) {
  var seconds = parseInt((timeinmilli = timeinmilli / 1000)) % 60;
  var minutes = parseInt((timeinmilli = timeinmilli / 60)) % 60;
  var hours = parseInt((timeinmilli = timeinmilli / 60)) % 24;
  var days = parseInt((timeinmilli = timeinmilli / 24));

  var string = "";

  if (days > 0) {
    string = string + days + "d";
  }

  if (hours > 0) {
    string = string + " " + hours + "h";
  }

  if (minutes > 0) {
    string = string + " " + minutes + "m";
  }

  if (seconds > 0) {
    string = string + " " + seconds + "s";
  }

  return string;
}

module.exports = [
  {
    type: "BigHeader",
    title: "Pat Murray's 2018",
    subtitle: "Year in Numbers",
    key: uuidv1()
  },
  {
    type: "Passage",
    content:
      'Welcome to my 2018 Year in Numbers, my annual review of statistics to give you a snapshot of my year.\n\nThis review contains some new sections, and some old. I focused much more on the passage of time and how it influenced my actions. You can see this in the multiple time based heatmaps on this page. I also tracked my every movement throughout the year and produced a location heatmap that I find quite interesting. Lastly, the new content includes an analysis of my messages. I produced a chart showing which words I sent the most during the year and how they compare to their counterpart words.\n\nI hope you like it!\n\nYou can jump to a section here if you don’t care about the whole page—though I’d obviously recommend you read it all.\n- [Activity](#Activity)\n- [Travel](#Travel)\n- [Skiing](#Skiing)\n- [Movement](#Movement)\n- [Music](#Music)\n- [Communication](#Communication)\n- [Twitter](#Twitter)\n- [Photos](#Photos)',
    key: uuidv1()
  },
  {
    type: "Asside",
    content: "Thanks to a couple of motorcycle falls at the beginning of the year I had a bit of a slow start to the year fitness and activity wise. However, I still managed to close my rings most days!",
    title: "Activity",
    key: uuidv1()
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        number: 2488122,
        prefix: "",
        suffix: "",
        title: "Steps",
        unit: "Total",
        figuretext: "A _thirteen percent_ decrease from last year",
        infotext: ""
      },
      {
        key: uuidv1(),
        number: 2126,
        prefix: "",
        suffix: "",
        title: "Distance Walked",
        unit: "KM",
        figuretext: "Just over the length of the _US West Coastline_",
        infotext:
          "On August 12, I walked/ran _22.83 KM_ (Fourteen of those running the City2Surf race)"
      }
    ],
    key: uuidv1()
  },
  {
    type: "BubbleMatrix",
    key: uuidv1(),
    title: "Steps Per Day",
    unit: "steps",
    color: "var(--accent-color)",
    row_titles: ["MON", "", "", "", "", "", "SUN"],
    column_titles: [
      {
        title: "Jan",
        columns: 5
      },
      {
        title: "Feb",
        columns: 4
      },
      {
        title: "Mar",
        columns: 4
      },
      {
        title: "Apr",
        columns: 5
      },
      {
        title: "May",
        columns: 4
      },
      {
        title: "Jun",
        columns: 4
      },
      {
        title: "Jul",
        columns: 5
      },
      {
        title: "Aug",
        columns: 4
      },
      {
        title: "Sep",
        columns: 4
      },
      {
        title: "Oct",
        columns: 5
      },
      {
        title: "Nov",
        columns: 4
      },
      {
        title: "Dec",
        columns: 5
      }
    ],
    data: [
      [
        7,
        4,
        13,
        9,
        4,
        10,
        2,
        1,
        14,
        5,
        12,
        5,
        14,
        14,
        12,
        7,
        13,
        10,
        14,
        3,
        11,
        5,
        12,
        16,
        13,
        3,
        13,
        5,
        10,
        4,
        5,
        3,
        22,
        4,
        17,
        10,
        34,
        7,
        8,
        3,
        4,
        13,
        6,
        18,
        15,
        7,
        6,
        16,
        19,
        11,
        8,
        4,
        0
      ],
      [
        5,
        4,
        8,
        10,
        16,
        4,
        4,
        7,
        10,
        8,
        14,
        7,
        11,
        19,
        27,
        5,
        9,
        5,
        5,
        17,
        7,
        2,
        30,
        11,
        2,
        5,
        15,
        20,
        7,
        9,
        8,
        11,
        8,
        15,
        2,
        7,
        21,
        25,
        17,
        9,
        3,
        12,
        6,
        4,
        23,
        6,
        8,
        16,
        6,
        5,
        12,
        2,
        2
      ],
      [
        7,
        3,
        7,
        9,
        9,
        13,
        12,
        9,
        7,
        13,
        19,
        25,
        8,
        6,
        5,
        7,
        22,
        3,
        4,
        22,
        15,
        3,
        22,
        11,
        3,
        6,
        18,
        8,
        8,
        5,
        4,
        8,
        8,
        18,
        11,
        9,
        17,
        11,
        5,
        1,
        3,
        11,
        12,
        13,
        7,
        6,
        4,
        8,
        12,
        8,
        11,
        8,
        2
      ],
      [
        4,
        1,
        11,
        8,
        10,
        23,
        11,
        8,
        8,
        6,
        16,
        9,
        8,
        3,
        9,
        4,
        9,
        6,
        3,
        8,
        4,
        8,
        17,
        9,
        6,
        3,
        10,
        3,
        1,
        5,
        14,
        6,
        10,
        5,
        15,
        5,
        15,
        16,
        11,
        1,
        3,
        4,
        13,
        5,
        3,
        5,
        13,
        3,
        22,
        5,
        5,
        12,
        12
      ],
      [
        4,
        0,
        6,
        6,
        5,
        26,
        15,
        5,
        7,
        16,
        11,
        10,
        3,
        5,
        11,
        6,
        1,
        3,
        6,
        3,
        7,
        7,
        10,
        11,
        7,
        7,
        14,
        6,
        5,
        8,
        1,
        6,
        17,
        30,
        14,
        2,
        2,
        18,
        3,
        5,
        4,
        2,
        3,
        6,
        2,
        7,
        6,
        6,
        8,
        13,
        3,
        4,
        2
      ],
      [
        2,
        3,
        0,
        2,
        17,
        9,
        3,
        6,
        3,
        5,
        17,
        5,
        3,
        5,
        4,
        7,
        1,
        4,
        7,
        4,
        12,
        0,
        1,
        13,
        6,
        2,
        1,
        3,
        7,
        5,
        4,
        8,
        9,
        30,
        4,
        1,
        6,
        12,
        7,
        6,
        2,
        7,
        6,
        3,
        6,
        4,
        5,
        4,
        12,
        4,
        6,
        6,
        3
      ],
      [
        0,
        6,
        7,
        3,
        9,
        4,
        15,
        20,
        8,
        6,
        11,
        10,
        5,
        13,
        9,
        1,
        11,
        9,
        5,
        8,
        7,
        5,
        1,
        8,
        8,
        4,
        3,
        2,
        8,
        3,
        9,
        7,
        5,
        48,
        6,
        4,
        6,
        13,
        6,
        5,
        2,
        2,
        3,
        5,
        16,
        5,
        11,
        7,
        6,
        2,
        2,
        5,
        2
      ]
    ]
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        number: 191,
        prefix: "",
        suffix: "",
        title: "Maximum Heart Rate",
        unit: "BPM",
        figuretext: "",
        infotext:
          "The same beats per minute as my _fourteenth_ most played song of the year, _I Wanna Get Better_ by Bleachers"
      },
      {
        key: uuidv1(),
        number: 174,
        prefix: "",
        suffix: "",
        title: "Closed Activity Rings",
        unit: "Days",
        figuretext: "",
        infotext:
          "I closed my stand ring _358 days_ of the year, my energy _305 days_ and exercise _180 days_"
      }
    ],
    key: uuidv1()
  },
  {
    type: "ActivityRingCollection",
    title: "Activity Rings by Day (Scroll Right)",
    key: uuidv1(),
    days: [
      {
        DATE: "2018-01-01",
        energy: 1833,
        energyGoal: 1360,
        energyProgress: 1.3475,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2160,
        exerciseGoal: 1800,
        exerciseProgress: 1.2
      },
      {
        DATE: "2018-01-02",
        energy: 2156,
        energyGoal: 1360,
        energyProgress: 1.5855,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2280,
        exerciseGoal: 1800,
        exerciseProgress: 1.2667
      },
      {
        DATE: "2018-01-03",
        energy: 2263,
        energyGoal: 1360,
        energyProgress: 1.664,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 3420,
        exerciseGoal: 1800,
        exerciseProgress: 1.9
      },
      {
        DATE: "2018-01-04",
        energy: 2060,
        energyGoal: 1360,
        energyProgress: 1.5148,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2280,
        exerciseGoal: 1800,
        exerciseProgress: 1.2667
      },
      {
        DATE: "2018-01-05",
        energy: 2973,
        energyGoal: 1360,
        energyProgress: 2.1861,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 4740,
        exerciseGoal: 1800,
        exerciseProgress: 2.6333
      },
      {
        DATE: "2018-01-06",
        energy: 1889,
        energyGoal: 1360,
        energyProgress: 1.3887,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1800,
        exerciseGoal: 1800,
        exerciseProgress: 1
      },
      {
        DATE: "2018-01-07",
        energy: 2755,
        energyGoal: 1360,
        energyProgress: 2.0258,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-01-08",
        energy: 1586,
        energyGoal: 1360,
        energyProgress: 1.1658,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1800,
        exerciseGoal: 1800,
        exerciseProgress: 1
      },
      {
        DATE: "2018-01-09",
        energy: 2276,
        energyGoal: 1360,
        energyProgress: 1.6733,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 3360,
        exerciseGoal: 1800,
        exerciseProgress: 1.8667
      },
      {
        DATE: "2018-01-10",
        energy: 2046,
        energyGoal: 1360,
        energyProgress: 1.5046,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2040,
        exerciseGoal: 1800,
        exerciseProgress: 1.1333
      },
      {
        DATE: "2018-01-11",
        energy: 2919,
        energyGoal: 1360,
        energyProgress: 2.1464,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 4800,
        exerciseGoal: 1800,
        exerciseProgress: 2.6667
      },
      {
        DATE: "2018-01-12",
        energy: 1951,
        energyGoal: 1360,
        energyProgress: 1.4344,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1740,
        exerciseGoal: 1800,
        exerciseProgress: 0.9667
      },
      {
        DATE: "2018-01-13",
        energy: 922,
        energyGoal: 1360,
        energyProgress: 0.6783,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 180,
        exerciseGoal: 1800,
        exerciseProgress: 0.1
      },
      {
        DATE: "2018-01-14",
        energy: 1022,
        energyGoal: 1360,
        energyProgress: 0.7511,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 180,
        exerciseGoal: 1800,
        exerciseProgress: 0.1
      },
      {
        DATE: "2018-01-15",
        energy: 2055,
        energyGoal: 1360,
        energyProgress: 1.5109,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2040,
        exerciseGoal: 1800,
        exerciseProgress: 1.1333
      },
      {
        DATE: "2018-01-16",
        energy: 1624,
        energyGoal: 1360,
        energyProgress: 1.1939,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 780,
        exerciseGoal: 1800,
        exerciseProgress: 0.4333
      },
      {
        DATE: "2018-01-17",
        energy: 1553,
        energyGoal: 1360,
        energyProgress: 1.1422,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 600,
        exerciseGoal: 1800,
        exerciseProgress: 0.3333
      },
      {
        DATE: "2018-01-18",
        energy: 2087,
        energyGoal: 1360,
        energyProgress: 1.5346,
        stand: 18,
        standGoal: 12,
        standProgress: 1.5,
        exercise: 1740,
        exerciseGoal: 1800,
        exerciseProgress: 0.9667
      },
      {
        DATE: "2018-01-19",
        energy: 1498,
        energyGoal: 1360,
        energyProgress: 1.1012,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-01-20",
        energy: 1917,
        energyGoal: 1360,
        energyProgress: 1.4095,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1920,
        exerciseGoal: 1800,
        exerciseProgress: 1.0667
      },
      {
        DATE: "2018-01-21",
        energy: 1158,
        energyGoal: 1360,
        energyProgress: 0.8518,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 480,
        exerciseGoal: 1800,
        exerciseProgress: 0.2667
      },
      {
        DATE: "2018-01-22",
        energy: 1535,
        energyGoal: 1360,
        energyProgress: 1.1285,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-01-23",
        energy: 2070,
        energyGoal: 1360,
        energyProgress: 1.522,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2520,
        exerciseGoal: 1800,
        exerciseProgress: 1.4
      },
      {
        DATE: "2018-01-24",
        energy: 1804,
        energyGoal: 1360,
        energyProgress: 1.3265,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-01-25",
        energy: 1669,
        energyGoal: 1360,
        energyProgress: 1.2275,
        stand: 18,
        standGoal: 12,
        standProgress: 1.5,
        exercise: 660,
        exerciseGoal: 1800,
        exerciseProgress: 0.3667
      },
      {
        DATE: "2018-01-26",
        energy: 1215,
        energyGoal: 1360,
        energyProgress: 0.8937,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 480,
        exerciseGoal: 1800,
        exerciseProgress: 0.2667
      },
      {
        DATE: "2018-01-27",
        energy: 2313,
        energyGoal: 1360,
        energyProgress: 1.7005,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2160,
        exerciseGoal: 1800,
        exerciseProgress: 1.2
      },
      {
        DATE: "2018-01-28",
        energy: 1714,
        energyGoal: 1360,
        energyProgress: 1.2601,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-01-29",
        energy: 1890,
        energyGoal: 1360,
        energyProgress: 1.3896,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-01-30",
        energy: 2012,
        energyGoal: 1360,
        energyProgress: 1.4796,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1740,
        exerciseGoal: 1800,
        exerciseProgress: 0.9667
      },
      {
        DATE: "2018-01-31",
        energy: 2130,
        energyGoal: 1360,
        energyProgress: 1.566,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 3660,
        exerciseGoal: 1800,
        exerciseProgress: 2.0333
      },
      {
        DATE: "2018-02-01",
        energy: 1939,
        energyGoal: 1360,
        energyProgress: 1.4259,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2040,
        exerciseGoal: 1800,
        exerciseProgress: 1.1333
      },
      {
        DATE: "2018-02-02",
        energy: 1809,
        energyGoal: 1360,
        energyProgress: 1.3299,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-02-03",
        energy: 3429,
        energyGoal: 1360,
        energyProgress: 2.5217,
        stand: 27,
        standGoal: 12,
        standProgress: 2.25,
        exercise: 2220,
        exerciseGoal: 1800,
        exerciseProgress: 1.2333
      },
      {
        DATE: "2018-02-04",
        energy: 3650,
        energyGoal: 1360,
        energyProgress: 2.684,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 4500,
        exerciseGoal: 1800,
        exerciseProgress: 2.5
      },
      {
        DATE: "2018-02-05",
        energy: 6629,
        energyGoal: 1360,
        energyProgress: 4.8746,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 9540,
        exerciseGoal: 1800,
        exerciseProgress: 5.3
      },
      {
        DATE: "2018-02-06",
        energy: 5968,
        energyGoal: 1360,
        energyProgress: 4.3886,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 8580,
        exerciseGoal: 1800,
        exerciseProgress: 4.7667
      },
      {
        DATE: "2018-02-07",
        energy: 5277,
        energyGoal: 1360,
        energyProgress: 3.8803,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 7140,
        exerciseGoal: 1800,
        exerciseProgress: 3.9667
      },
      {
        DATE: "2018-02-08",
        energy: 6325,
        energyGoal: 1360,
        energyProgress: 4.6508,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 9360,
        exerciseGoal: 1800,
        exerciseProgress: 5.2
      },
      {
        DATE: "2018-02-09",
        energy: 5966,
        energyGoal: 1360,
        energyProgress: 4.3871,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 9060,
        exerciseGoal: 1800,
        exerciseProgress: 5.0333
      },
      {
        DATE: "2018-02-10",
        energy: 0,
        energyGoal: 1360,
        energyProgress: 0,
        stand: 0,
        standGoal: 12,
        standProgress: 0,
        exercise: 0,
        exerciseGoal: 1800,
        exerciseProgress: 0
      },
      {
        DATE: "2018-02-11",
        energy: 2409,
        energyGoal: 1360,
        energyProgress: 1.7717,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2340,
        exerciseGoal: 1800,
        exerciseProgress: 1.3
      },
      {
        DATE: "2018-02-12",
        energy: 1993,
        energyGoal: 1360,
        energyProgress: 1.4657,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2820,
        exerciseGoal: 1800,
        exerciseProgress: 1.5667
      },
      {
        DATE: "2018-02-13",
        energy: 1509,
        energyGoal: 1360,
        energyProgress: 1.1094,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2040,
        exerciseGoal: 1800,
        exerciseProgress: 1.1333
      },
      {
        DATE: "2018-02-14",
        energy: 1931,
        energyGoal: 1360,
        energyProgress: 1.4198,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1260,
        exerciseGoal: 1800,
        exerciseProgress: 0.7
      },
      {
        DATE: "2018-02-15",
        energy: 2232,
        energyGoal: 1360,
        energyProgress: 1.6415,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2880,
        exerciseGoal: 1800,
        exerciseProgress: 1.6
      },
      {
        DATE: "2018-02-16",
        energy: 1801,
        energyGoal: 1360,
        energyProgress: 1.3245,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 900,
        exerciseGoal: 1800,
        exerciseProgress: 0.5
      },
      {
        DATE: "2018-02-17",
        energy: 999,
        energyGoal: 1360,
        energyProgress: 0.7343,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 240,
        exerciseGoal: 1800,
        exerciseProgress: 0.1333
      },
      {
        DATE: "2018-02-18",
        energy: 1725,
        energyGoal: 1360,
        energyProgress: 1.2682,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2940,
        exerciseGoal: 1800,
        exerciseProgress: 1.6333
      },
      {
        DATE: "2018-02-19",
        energy: 1665,
        energyGoal: 1360,
        energyProgress: 1.2241,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 1440,
        exerciseGoal: 1800,
        exerciseProgress: 0.8
      },
      {
        DATE: "2018-02-20",
        energy: 1713,
        energyGoal: 1360,
        energyProgress: 1.2597,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 720,
        exerciseGoal: 1800,
        exerciseProgress: 0.4
      },
      {
        DATE: "2018-02-21",
        energy: 1336,
        energyGoal: 1360,
        energyProgress: 0.9824,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 840,
        exerciseGoal: 1800,
        exerciseProgress: 0.4667
      },
      {
        DATE: "2018-02-22",
        energy: 2053,
        energyGoal: 1360,
        energyProgress: 1.5094,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1920,
        exerciseGoal: 1800,
        exerciseProgress: 1.0667
      },
      {
        DATE: "2018-02-23",
        energy: 1821,
        energyGoal: 1360,
        energyProgress: 1.339,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1860,
        exerciseGoal: 1800,
        exerciseProgress: 1.0333
      },
      {
        DATE: "2018-02-24",
        energy: 2856,
        energyGoal: 1360,
        energyProgress: 2.1001,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 5520,
        exerciseGoal: 1800,
        exerciseProgress: 3.0667
      },
      {
        DATE: "2018-02-25",
        energy: 1007,
        energyGoal: 1360,
        energyProgress: 0.7406,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1800,
        exerciseGoal: 1800,
        exerciseProgress: 1
      },
      {
        DATE: "2018-02-26",
        energy: 1440,
        energyGoal: 1360,
        energyProgress: 1.0588,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 840,
        exerciseGoal: 1800,
        exerciseProgress: 0.4667
      },
      {
        DATE: "2018-02-27",
        energy: 1724,
        energyGoal: 1360,
        energyProgress: 1.2674,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-02-28",
        energy: 1533,
        energyGoal: 1360,
        energyProgress: 1.1272,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-03-01",
        energy: 1598,
        energyGoal: 1360,
        energyProgress: 1.1748,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-03-02",
        energy: 1655,
        energyGoal: 1360,
        energyProgress: 1.2171,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-03-03",
        energy: 1556,
        energyGoal: 1360,
        energyProgress: 1.144,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-03-04",
        energy: 685,
        energyGoal: 1360,
        energyProgress: 0.5036,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 240,
        exerciseGoal: 1800,
        exerciseProgress: 0.1333
      },
      {
        DATE: "2018-03-05",
        energy: 1862,
        energyGoal: 1360,
        energyProgress: 1.3689,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1860,
        exerciseGoal: 1800,
        exerciseProgress: 1.0333
      },
      {
        DATE: "2018-03-06",
        energy: 1511,
        energyGoal: 1360,
        energyProgress: 1.1109,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-03-07",
        energy: 1189,
        energyGoal: 1360,
        energyProgress: 0.874,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-03-08",
        energy: 2085,
        energyGoal: 1360,
        energyProgress: 1.533,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1740,
        exerciseGoal: 1800,
        exerciseProgress: 0.9667
      },
      {
        DATE: "2018-03-09",
        energy: 1919,
        energyGoal: 1360,
        energyProgress: 1.4107,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1500,
        exerciseGoal: 1800,
        exerciseProgress: 0.8333
      },
      {
        DATE: "2018-03-10",
        energy: 2125,
        energyGoal: 1360,
        energyProgress: 1.5624,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 3000,
        exerciseGoal: 1800,
        exerciseProgress: 1.6667
      },
      {
        DATE: "2018-03-11",
        energy: 1704,
        energyGoal: 1360,
        energyProgress: 1.2528,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2640,
        exerciseGoal: 1800,
        exerciseProgress: 1.4667
      },
      {
        DATE: "2018-03-12",
        energy: 1939,
        energyGoal: 1360,
        energyProgress: 1.4255,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2100,
        exerciseGoal: 1800,
        exerciseProgress: 1.1667
      },
      {
        DATE: "2018-03-13",
        energy: 1418,
        energyGoal: 1360,
        energyProgress: 1.0429,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 600,
        exerciseGoal: 1800,
        exerciseProgress: 0.3333
      },
      {
        DATE: "2018-03-14",
        energy: 1126,
        energyGoal: 1360,
        energyProgress: 0.8283,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 540,
        exerciseGoal: 1800,
        exerciseProgress: 0.3
      },
      {
        DATE: "2018-03-15",
        energy: 989,
        energyGoal: 1360,
        energyProgress: 0.7271,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 480,
        exerciseGoal: 1800,
        exerciseProgress: 0.2667
      },
      {
        DATE: "2018-03-16",
        energy: 1772,
        energyGoal: 1360,
        energyProgress: 1.3032,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-03-17",
        energy: 1306,
        energyGoal: 1360,
        energyProgress: 0.9599,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-03-18",
        energy: 918,
        energyGoal: 1360,
        energyProgress: 0.6747,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 60,
        exerciseGoal: 1800,
        exerciseProgress: 0.0333
      },
      {
        DATE: "2018-03-19",
        energy: 1531,
        energyGoal: 1360,
        energyProgress: 1.1256,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-03-20",
        energy: 1840,
        energyGoal: 1360,
        energyProgress: 1.3529,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-03-21",
        energy: 1506,
        energyGoal: 1360,
        energyProgress: 1.107,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-03-22",
        energy: 1807,
        energyGoal: 1360,
        energyProgress: 1.329,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1920,
        exerciseGoal: 1800,
        exerciseProgress: 1.0667
      },
      {
        DATE: "2018-03-23",
        energy: 1728,
        energyGoal: 1360,
        energyProgress: 1.2706,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-03-24",
        energy: 1581,
        energyGoal: 1360,
        energyProgress: 1.1622,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-03-25",
        energy: 1583,
        energyGoal: 1360,
        energyProgress: 1.1641,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2520,
        exerciseGoal: 1800,
        exerciseProgress: 1.4
      },
      {
        DATE: "2018-03-26",
        energy: 1368,
        energyGoal: 1360,
        energyProgress: 1.0058,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-03-27",
        energy: 1643,
        energyGoal: 1360,
        energyProgress: 1.2081,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-03-28",
        energy: 1639,
        energyGoal: 1360,
        energyProgress: 1.2049,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1440,
        exerciseGoal: 1800,
        exerciseProgress: 0.8
      },
      {
        DATE: "2018-03-29",
        energy: 1628,
        energyGoal: 1360,
        energyProgress: 1.197,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1320,
        exerciseGoal: 1800,
        exerciseProgress: 0.7333
      },
      {
        DATE: "2018-03-30",
        energy: 2342,
        energyGoal: 1360,
        energyProgress: 1.722,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-03-31",
        energy: 1510,
        energyGoal: 1360,
        energyProgress: 1.1106,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-04-01",
        energy: 1969,
        energyGoal: 1360,
        energyProgress: 1.4478,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 3120,
        exerciseGoal: 1800,
        exerciseProgress: 1.7333
      },
      {
        DATE: "2018-04-02",
        energy: 1242,
        energyGoal: 1360,
        energyProgress: 0.9134,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1440,
        exerciseGoal: 1800,
        exerciseProgress: 0.8
      },
      {
        DATE: "2018-04-03",
        energy: 1629,
        energyGoal: 1360,
        energyProgress: 1.1977,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1320,
        exerciseGoal: 1800,
        exerciseProgress: 0.7333
      },
      {
        DATE: "2018-04-04",
        energy: 1655,
        energyGoal: 1360,
        energyProgress: 1.2168,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1320,
        exerciseGoal: 1800,
        exerciseProgress: 0.7333
      },
      {
        DATE: "2018-04-05",
        energy: 1357,
        energyGoal: 1360,
        energyProgress: 0.9978,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-04-06",
        energy: 1605,
        energyGoal: 1360,
        energyProgress: 1.1799,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-04-07",
        energy: 2129,
        energyGoal: 1360,
        energyProgress: 1.5655,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 4020,
        exerciseGoal: 1800,
        exerciseProgress: 2.2333
      },
      {
        DATE: "2018-04-08",
        energy: 1313,
        energyGoal: 1360,
        energyProgress: 0.9654,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 360,
        exerciseGoal: 1800,
        exerciseProgress: 0.2
      },
      {
        DATE: "2018-04-09",
        energy: 2184,
        energyGoal: 1360,
        energyProgress: 1.606,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2220,
        exerciseGoal: 1800,
        exerciseProgress: 1.2333
      },
      {
        DATE: "2018-04-10",
        energy: 1954,
        energyGoal: 1360,
        energyProgress: 1.4367,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-04-11",
        energy: 2149,
        energyGoal: 1360,
        energyProgress: 1.5798,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2460,
        exerciseGoal: 1800,
        exerciseProgress: 1.3667
      },
      {
        DATE: "2018-04-12",
        energy: 2117,
        energyGoal: 1360,
        energyProgress: 1.5568,
        stand: 18,
        standGoal: 12,
        standProgress: 1.5,
        exercise: 2880,
        exerciseGoal: 1800,
        exerciseProgress: 1.6
      },
      {
        DATE: "2018-04-13",
        energy: 832,
        energyGoal: 1360,
        energyProgress: 0.6116,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 60,
        exerciseGoal: 1800,
        exerciseProgress: 0.0333
      },
      {
        DATE: "2018-04-14",
        energy: 1229,
        energyGoal: 1360,
        energyProgress: 0.9038,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-04-15",
        energy: 1682,
        energyGoal: 1360,
        energyProgress: 1.2366,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2940,
        exerciseGoal: 1800,
        exerciseProgress: 1.6333
      },
      {
        DATE: "2018-04-16",
        energy: 1404,
        energyGoal: 1360,
        energyProgress: 1.0327,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 720,
        exerciseGoal: 1800,
        exerciseProgress: 0.4
      },
      {
        DATE: "2018-04-17",
        energy: 1899,
        energyGoal: 1360,
        energyProgress: 1.3965,
        stand: 18,
        standGoal: 12,
        standProgress: 1.5,
        exercise: 1680,
        exerciseGoal: 1800,
        exerciseProgress: 0.9333
      },
      {
        DATE: "2018-04-18",
        energy: 1425,
        energyGoal: 1360,
        energyProgress: 1.0476,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 780,
        exerciseGoal: 1800,
        exerciseProgress: 0.4333
      },
      {
        DATE: "2018-04-19",
        energy: 2330,
        energyGoal: 1360,
        energyProgress: 1.713,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 3060,
        exerciseGoal: 1800,
        exerciseProgress: 1.7
      },
      {
        DATE: "2018-04-20",
        energy: 2792,
        energyGoal: 1360,
        energyProgress: 2.0528,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 3660,
        exerciseGoal: 1800,
        exerciseProgress: 2.0333
      },
      {
        DATE: "2018-04-21",
        energy: 2110,
        energyGoal: 1360,
        energyProgress: 1.5514,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-04-22",
        energy: 1785,
        energyGoal: 1360,
        energyProgress: 1.3128,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-04-23",
        energy: 1647,
        energyGoal: 1360,
        energyProgress: 1.2111,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2280,
        exerciseGoal: 1800,
        exerciseProgress: 1.2667
      },
      {
        DATE: "2018-04-24",
        energy: 2281,
        energyGoal: 1360,
        energyProgress: 1.6772,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2040,
        exerciseGoal: 1800,
        exerciseProgress: 1.1333
      },
      {
        DATE: "2018-04-25",
        energy: 1870,
        energyGoal: 1360,
        energyProgress: 1.3749,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 1740,
        exerciseGoal: 1800,
        exerciseProgress: 0.9667
      },
      {
        DATE: "2018-04-26",
        energy: 2067,
        energyGoal: 1360,
        energyProgress: 1.5196,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-04-27",
        energy: 1711,
        energyGoal: 1360,
        energyProgress: 1.2578,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-04-28",
        energy: 1674,
        energyGoal: 1360,
        energyProgress: 1.2305,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2160,
        exerciseGoal: 1800,
        exerciseProgress: 1.2
      },
      {
        DATE: "2018-04-29",
        energy: 1614,
        energyGoal: 1360,
        energyProgress: 1.1869,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 1440,
        exerciseGoal: 1800,
        exerciseProgress: 0.8
      },
      {
        DATE: "2018-04-30",
        energy: 1906,
        energyGoal: 1360,
        energyProgress: 1.4014,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-05-01",
        energy: 1653,
        energyGoal: 1360,
        energyProgress: 1.2156,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2160,
        exerciseGoal: 1800,
        exerciseProgress: 1.2
      },
      {
        DATE: "2018-05-02",
        energy: 1746,
        energyGoal: 1360,
        energyProgress: 1.2839,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1440,
        exerciseGoal: 1800,
        exerciseProgress: 0.8
      },
      {
        DATE: "2018-05-03",
        energy: 1361,
        energyGoal: 1360,
        energyProgress: 1.0006,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 480,
        exerciseGoal: 1800,
        exerciseProgress: 0.2667
      },
      {
        DATE: "2018-05-04",
        energy: 1884,
        energyGoal: 1360,
        energyProgress: 1.3854,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1920,
        exerciseGoal: 1800,
        exerciseProgress: 1.0667
      },
      {
        DATE: "2018-05-05",
        energy: 2029,
        energyGoal: 1360,
        energyProgress: 1.4918,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 3420,
        exerciseGoal: 1800,
        exerciseProgress: 1.9
      },
      {
        DATE: "2018-05-06",
        energy: 1250,
        energyGoal: 1360,
        energyProgress: 0.9188,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 780,
        exerciseGoal: 1800,
        exerciseProgress: 0.4333
      },
      {
        DATE: "2018-05-07",
        energy: 1377,
        energyGoal: 1360,
        energyProgress: 1.0126,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-05-08",
        energy: 1491,
        energyGoal: 1360,
        energyProgress: 1.0964,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1320,
        exerciseGoal: 1800,
        exerciseProgress: 0.7333
      },
      {
        DATE: "2018-05-09",
        energy: 2405,
        energyGoal: 1360,
        energyProgress: 1.7684,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 3000,
        exerciseGoal: 1800,
        exerciseProgress: 1.6667
      },
      {
        DATE: "2018-05-10",
        energy: 1564,
        energyGoal: 1360,
        energyProgress: 1.1503,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1260,
        exerciseGoal: 1800,
        exerciseProgress: 0.7
      },
      {
        DATE: "2018-05-11",
        energy: 1758,
        energyGoal: 1360,
        energyProgress: 1.2928,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-05-12",
        energy: 1098,
        energyGoal: 1360,
        energyProgress: 0.8073,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 600,
        exerciseGoal: 1800,
        exerciseProgress: 0.3333
      },
      {
        DATE: "2018-05-13",
        energy: 1105,
        energyGoal: 1360,
        energyProgress: 0.8122,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 660,
        exerciseGoal: 1800,
        exerciseProgress: 0.3667
      },
      {
        DATE: "2018-05-14",
        energy: 1508,
        energyGoal: 1360,
        energyProgress: 1.1087,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-05-15",
        energy: 1442,
        energyGoal: 1360,
        energyProgress: 1.0605,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 900,
        exerciseGoal: 1800,
        exerciseProgress: 0.5
      },
      {
        DATE: "2018-05-16",
        energy: 2697,
        energyGoal: 1360,
        energyProgress: 1.9828,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 3060,
        exerciseGoal: 1800,
        exerciseProgress: 1.7
      },
      {
        DATE: "2018-05-17",
        energy: 2066,
        energyGoal: 1360,
        energyProgress: 1.5188,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2700,
        exerciseGoal: 1800,
        exerciseProgress: 1.5
      },
      {
        DATE: "2018-05-18",
        energy: 2330,
        energyGoal: 1360,
        energyProgress: 1.7133,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 3180,
        exerciseGoal: 1800,
        exerciseProgress: 1.7667
      },
      {
        DATE: "2018-05-19",
        energy: 1816,
        energyGoal: 1360,
        energyProgress: 1.3356,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 3000,
        exerciseGoal: 1800,
        exerciseProgress: 1.6667
      },
      {
        DATE: "2018-05-20",
        energy: 2002,
        energyGoal: 1360,
        energyProgress: 1.472,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-05-21",
        energy: 1609,
        energyGoal: 1360,
        energyProgress: 1.1834,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-05-22",
        energy: 1964,
        energyGoal: 1360,
        energyProgress: 1.4439,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 3180,
        exerciseGoal: 1800,
        exerciseProgress: 1.7667
      },
      {
        DATE: "2018-05-23",
        energy: 1476,
        energyGoal: 1360,
        energyProgress: 1.0855,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 600,
        exerciseGoal: 1800,
        exerciseProgress: 0.3333
      },
      {
        DATE: "2018-05-24",
        energy: 2798,
        energyGoal: 1360,
        energyProgress: 2.0573,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2880,
        exerciseGoal: 1800,
        exerciseProgress: 1.6
      },
      {
        DATE: "2018-05-25",
        energy: 1817,
        energyGoal: 1360,
        energyProgress: 1.336,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 1020,
        exerciseGoal: 1800,
        exerciseProgress: 0.5667
      },
      {
        DATE: "2018-05-26",
        energy: 2507,
        energyGoal: 1360,
        energyProgress: 1.8433,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 4080,
        exerciseGoal: 1800,
        exerciseProgress: 2.2667
      },
      {
        DATE: "2018-05-27",
        energy: 2631,
        energyGoal: 1360,
        energyProgress: 1.9346,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 4140,
        exerciseGoal: 1800,
        exerciseProgress: 2.3
      },
      {
        DATE: "2018-05-28",
        energy: 2083,
        energyGoal: 1360,
        energyProgress: 1.5316,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1920,
        exerciseGoal: 1800,
        exerciseProgress: 1.0667
      },
      {
        DATE: "2018-05-29",
        energy: 1232,
        energyGoal: 1360,
        energyProgress: 0.9062,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 780,
        exerciseGoal: 1800,
        exerciseProgress: 0.4333
      },
      {
        DATE: "2018-05-30",
        energy: 1845,
        energyGoal: 1360,
        energyProgress: 1.3567,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 2160,
        exerciseGoal: 1800,
        exerciseProgress: 1.2
      },
      {
        DATE: "2018-05-31",
        energy: 1601,
        energyGoal: 1360,
        energyProgress: 1.1772,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-06-01",
        energy: 2229,
        energyGoal: 1360,
        energyProgress: 1.6389,
        stand: 21,
        standGoal: 12,
        standProgress: 1.75,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-06-02",
        energy: 2562,
        energyGoal: 1360,
        energyProgress: 1.884,
        stand: 19,
        standGoal: 12,
        standProgress: 1.5833,
        exercise: 2400,
        exerciseGoal: 1800,
        exerciseProgress: 1.3333
      },
      {
        DATE: "2018-06-03",
        energy: 3055,
        energyGoal: 1360,
        energyProgress: 2.2462,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 3240,
        exerciseGoal: 1800,
        exerciseProgress: 1.8
      },
      {
        DATE: "2018-06-04",
        energy: 2570,
        energyGoal: 1360,
        energyProgress: 1.8896,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1920,
        exerciseGoal: 1800,
        exerciseProgress: 1.0667
      },
      {
        DATE: "2018-06-05",
        energy: 2368,
        energyGoal: 1360,
        energyProgress: 1.7413,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2640,
        exerciseGoal: 1800,
        exerciseProgress: 1.4667
      },
      {
        DATE: "2018-06-06",
        energy: 2743,
        energyGoal: 1360,
        energyProgress: 2.0166,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 3960,
        exerciseGoal: 1800,
        exerciseProgress: 2.2
      },
      {
        DATE: "2018-06-07",
        energy: 2698,
        energyGoal: 1360,
        energyProgress: 1.9836,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 3720,
        exerciseGoal: 1800,
        exerciseProgress: 2.0667
      },
      {
        DATE: "2018-06-08",
        energy: 1941,
        energyGoal: 1360,
        energyProgress: 1.4269,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2460,
        exerciseGoal: 1800,
        exerciseProgress: 1.3667
      },
      {
        DATE: "2018-06-09",
        energy: 0,
        energyGoal: 1360,
        energyProgress: 0,
        stand: 0,
        standGoal: 12,
        standProgress: 0,
        exercise: 0,
        exerciseGoal: 1800,
        exerciseProgress: 0
      },
      {
        DATE: "2018-06-10",
        energy: 2536,
        energyGoal: 1360,
        energyProgress: 1.8644,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2340,
        exerciseGoal: 1800,
        exerciseProgress: 1.3
      },
      {
        DATE: "2018-06-11",
        energy: 2419,
        energyGoal: 1360,
        energyProgress: 1.7785,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 3960,
        exerciseGoal: 1800,
        exerciseProgress: 2.2
      },
      {
        DATE: "2018-06-12",
        energy: 2034,
        energyGoal: 1360,
        energyProgress: 1.4957,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 3240,
        exerciseGoal: 1800,
        exerciseProgress: 1.8
      },
      {
        DATE: "2018-06-13",
        energy: 1490,
        energyGoal: 1360,
        energyProgress: 1.0954,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1740,
        exerciseGoal: 1800,
        exerciseProgress: 0.9667
      },
      {
        DATE: "2018-06-14",
        energy: 4616,
        energyGoal: 1360,
        energyProgress: 3.394,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 8220,
        exerciseGoal: 1800,
        exerciseProgress: 4.5667
      },
      {
        DATE: "2018-06-15",
        energy: 2404,
        energyGoal: 1360,
        energyProgress: 1.7676,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2700,
        exerciseGoal: 1800,
        exerciseProgress: 1.5
      },
      {
        DATE: "2018-06-16",
        energy: 1143,
        energyGoal: 1360,
        energyProgress: 0.8407,
        stand: 10,
        standGoal: 12,
        standProgress: 0.8333,
        exercise: 780,
        exerciseGoal: 1800,
        exerciseProgress: 0.4333
      },
      {
        DATE: "2018-06-17",
        energy: 1087,
        energyGoal: 1460,
        energyProgress: 0.7446,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 660,
        exerciseGoal: 1800,
        exerciseProgress: 0.3667
      },
      {
        DATE: "2018-06-18",
        energy: 1460,
        energyGoal: 1460,
        energyProgress: 1,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 1980,
        exerciseGoal: 1800,
        exerciseProgress: 1.1
      },
      {
        DATE: "2018-06-19",
        energy: 1465,
        energyGoal: 1460,
        energyProgress: 1.0035,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1680,
        exerciseGoal: 1800,
        exerciseProgress: 0.9333
      },
      {
        DATE: "2018-06-20",
        energy: 1863,
        energyGoal: 1460,
        energyProgress: 1.2758,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2280,
        exerciseGoal: 1800,
        exerciseProgress: 1.2667
      },
      {
        DATE: "2018-06-21",
        energy: 1982,
        energyGoal: 1460,
        energyProgress: 1.3574,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-06-22",
        energy: 1367,
        energyGoal: 1460,
        energyProgress: 0.9362,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 900,
        exerciseGoal: 1800,
        exerciseProgress: 0.5
      },
      {
        DATE: "2018-06-23",
        energy: 1261,
        energyGoal: 1460,
        energyProgress: 0.8638,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 780,
        exerciseGoal: 1800,
        exerciseProgress: 0.4333
      },
      {
        DATE: "2018-06-24",
        energy: 1673,
        energyGoal: 1460,
        energyProgress: 1.1457,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-06-25",
        energy: 1543,
        energyGoal: 1460,
        energyProgress: 1.0568,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1800,
        exerciseGoal: 1800,
        exerciseProgress: 1
      },
      {
        DATE: "2018-06-26",
        energy: 1944,
        energyGoal: 1460,
        energyProgress: 1.3316,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2940,
        exerciseGoal: 1800,
        exerciseProgress: 1.6333
      },
      {
        DATE: "2018-06-27",
        energy: 1857,
        energyGoal: 1460,
        energyProgress: 1.2716,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-06-28",
        energy: 2597,
        energyGoal: 1460,
        energyProgress: 1.7788,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 4380,
        exerciseGoal: 1800,
        exerciseProgress: 2.4333
      },
      {
        DATE: "2018-06-29",
        energy: 4308,
        energyGoal: 1460,
        energyProgress: 2.9508,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 7260,
        exerciseGoal: 1800,
        exerciseProgress: 4.0333
      },
      {
        DATE: "2018-06-30",
        energy: 1492,
        energyGoal: 1460,
        energyProgress: 1.0221,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-07-01",
        energy: 886,
        energyGoal: 1460,
        energyProgress: 0.6067,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 120,
        exerciseGoal: 1800,
        exerciseProgress: 0.0667
      },
      {
        DATE: "2018-07-02",
        energy: 4678,
        energyGoal: 1460,
        energyProgress: 3.204,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 6840,
        exerciseGoal: 1800,
        exerciseProgress: 3.8
      },
      {
        DATE: "2018-07-03",
        energy: 1773,
        energyGoal: 1460,
        energyProgress: 1.2141,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1860,
        exerciseGoal: 1800,
        exerciseProgress: 1.0333
      },
      {
        DATE: "2018-07-04",
        energy: 2797,
        energyGoal: 1460,
        energyProgress: 1.9158,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 3480,
        exerciseGoal: 1800,
        exerciseProgress: 1.9333
      },
      {
        DATE: "2018-07-05",
        energy: 1744,
        energyGoal: 1460,
        energyProgress: 1.1945,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1680,
        exerciseGoal: 1800,
        exerciseProgress: 0.9333
      },
      {
        DATE: "2018-07-06",
        energy: 1997,
        energyGoal: 1460,
        energyProgress: 1.368,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2280,
        exerciseGoal: 1800,
        exerciseProgress: 1.2667
      },
      {
        DATE: "2018-07-07",
        energy: 2146,
        energyGoal: 1460,
        energyProgress: 1.4698,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 3060,
        exerciseGoal: 1800,
        exerciseProgress: 1.7
      },
      {
        DATE: "2018-07-08",
        energy: 1245,
        energyGoal: 1460,
        energyProgress: 0.853,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-07-09",
        energy: 1879,
        energyGoal: 1460,
        energyProgress: 1.2871,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 3000,
        exerciseGoal: 1800,
        exerciseProgress: 1.6667
      },
      {
        DATE: "2018-07-10",
        energy: 1627,
        energyGoal: 1460,
        energyProgress: 1.1142,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-07-11",
        energy: 3149,
        energyGoal: 1460,
        energyProgress: 2.1568,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 3960,
        exerciseGoal: 1800,
        exerciseProgress: 2.2
      },
      {
        DATE: "2018-07-12",
        energy: 1522,
        energyGoal: 1460,
        energyProgress: 1.0422,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1260,
        exerciseGoal: 1800,
        exerciseProgress: 0.7
      },
      {
        DATE: "2018-07-13",
        energy: 1456,
        energyGoal: 1460,
        energyProgress: 0.9973,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-07-14",
        energy: 2001,
        energyGoal: 1460,
        energyProgress: 1.3705,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1860,
        exerciseGoal: 1800,
        exerciseProgress: 1.0333
      },
      {
        DATE: "2018-07-15",
        energy: 1673,
        energyGoal: 1460,
        energyProgress: 1.1458,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1680,
        exerciseGoal: 1800,
        exerciseProgress: 0.9333
      },
      {
        DATE: "2018-07-16",
        energy: 1483,
        energyGoal: 1460,
        energyProgress: 1.0155,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1800,
        exerciseGoal: 1800,
        exerciseProgress: 1
      },
      {
        DATE: "2018-07-17",
        energy: 2978,
        energyGoal: 1460,
        energyProgress: 2.0398,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 3720,
        exerciseGoal: 1800,
        exerciseProgress: 2.0667
      },
      {
        DATE: "2018-07-18",
        energy: 1956,
        energyGoal: 1460,
        energyProgress: 1.3395,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 3120,
        exerciseGoal: 1800,
        exerciseProgress: 1.7333
      },
      {
        DATE: "2018-07-19",
        energy: 2523,
        energyGoal: 1460,
        energyProgress: 1.728,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 4080,
        exerciseGoal: 1800,
        exerciseProgress: 2.2667
      },
      {
        DATE: "2018-07-20",
        energy: 2678,
        energyGoal: 1460,
        energyProgress: 1.8345,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 4200,
        exerciseGoal: 1800,
        exerciseProgress: 2.3333
      },
      {
        DATE: "2018-07-21",
        energy: 1841,
        energyGoal: 1460,
        energyProgress: 1.2608,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2640,
        exerciseGoal: 1800,
        exerciseProgress: 1.4667
      },
      {
        DATE: "2018-07-22",
        energy: 928,
        energyGoal: 1460,
        energyProgress: 0.6357,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 480,
        exerciseGoal: 1800,
        exerciseProgress: 0.2667
      },
      {
        DATE: "2018-07-23",
        energy: 1305,
        energyGoal: 1460,
        energyProgress: 0.8938,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-07-24",
        energy: 1586,
        energyGoal: 1460,
        energyProgress: 1.0862,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2040,
        exerciseGoal: 1800,
        exerciseProgress: 1.1333
      },
      {
        DATE: "2018-07-25",
        energy: 2188,
        energyGoal: 1460,
        energyProgress: 1.4989,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-07-26",
        energy: 1442,
        energyGoal: 1460,
        energyProgress: 0.9877,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 1680,
        exerciseGoal: 1800,
        exerciseProgress: 0.9333
      },
      {
        DATE: "2018-07-27",
        energy: 1845,
        energyGoal: 1460,
        energyProgress: 1.264,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 5820,
        exerciseGoal: 1800,
        exerciseProgress: 3.2333
      },
      {
        DATE: "2018-07-28",
        energy: 5285,
        energyGoal: 1460,
        energyProgress: 3.6196,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 7080,
        exerciseGoal: 1800,
        exerciseProgress: 3.9333
      },
      {
        DATE: "2018-07-29",
        energy: 1213,
        energyGoal: 1460,
        energyProgress: 0.8311,
        stand: 10,
        standGoal: 12,
        standProgress: 0.8333,
        exercise: 900,
        exerciseGoal: 1800,
        exerciseProgress: 0.5
      },
      {
        DATE: "2018-07-30",
        energy: 3940,
        energyGoal: 1460,
        energyProgress: 2.6989,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 5100,
        exerciseGoal: 1800,
        exerciseProgress: 2.8333
      },
      {
        DATE: "2018-07-31",
        energy: 1740,
        energyGoal: 1460,
        energyProgress: 1.1917,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 3720,
        exerciseGoal: 1800,
        exerciseProgress: 2.0667
      },
      {
        DATE: "2018-08-01",
        energy: 1636,
        energyGoal: 1460,
        energyProgress: 1.1203,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-08-02",
        energy: 1518,
        energyGoal: 1460,
        energyProgress: 1.04,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-08-03",
        energy: 2553,
        energyGoal: 1460,
        energyProgress: 1.7489,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 3360,
        exerciseGoal: 1800,
        exerciseProgress: 1.8667
      },
      {
        DATE: "2018-08-04",
        energy: 1309,
        energyGoal: 1460,
        energyProgress: 0.8963,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 900,
        exerciseGoal: 1800,
        exerciseProgress: 0.5
      },
      {
        DATE: "2018-08-05",
        energy: 1918,
        energyGoal: 1460,
        energyProgress: 1.3134,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-08-06",
        energy: 1673,
        energyGoal: 1460,
        energyProgress: 1.1456,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1680,
        exerciseGoal: 1800,
        exerciseProgress: 0.9333
      },
      {
        DATE: "2018-08-07",
        energy: 1641,
        energyGoal: 1460,
        energyProgress: 1.1238,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1980,
        exerciseGoal: 1800,
        exerciseProgress: 1.1
      },
      {
        DATE: "2018-08-08",
        energy: 2744,
        energyGoal: 1460,
        energyProgress: 1.8793,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 4140,
        exerciseGoal: 1800,
        exerciseProgress: 2.3
      },
      {
        DATE: "2018-08-09",
        energy: 1744,
        energyGoal: 1460,
        energyProgress: 1.1948,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-08-10",
        energy: 2341,
        energyGoal: 1460,
        energyProgress: 1.6037,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 3780,
        exerciseGoal: 1800,
        exerciseProgress: 2.1
      },
      {
        DATE: "2018-08-11",
        energy: 1657,
        energyGoal: 1460,
        energyProgress: 1.1348,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 780,
        exerciseGoal: 1800,
        exerciseProgress: 0.4333
      },
      {
        DATE: "2018-08-12",
        energy: 5704,
        energyGoal: 1460,
        energyProgress: 3.9069,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 10020,
        exerciseGoal: 1800,
        exerciseProgress: 5.5667
      },
      {
        DATE: "2018-08-13",
        energy: 1464,
        energyGoal: 1460,
        energyProgress: 1.0025,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-08-14",
        energy: 1150,
        energyGoal: 1460,
        energyProgress: 0.7877,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-08-15",
        energy: 1534,
        energyGoal: 1460,
        energyProgress: 1.0506,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-08-16",
        energy: 2363,
        energyGoal: 1460,
        energyProgress: 1.6184,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 3060,
        exerciseGoal: 1800,
        exerciseProgress: 1.7
      },
      {
        DATE: "2018-08-17",
        energy: 2053,
        energyGoal: 1460,
        energyProgress: 1.4062,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2100,
        exerciseGoal: 1800,
        exerciseProgress: 1.1667
      },
      {
        DATE: "2018-08-18",
        energy: 1942,
        energyGoal: 1460,
        energyProgress: 1.3304,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2220,
        exerciseGoal: 1800,
        exerciseProgress: 1.2333
      },
      {
        DATE: "2018-08-19",
        energy: 1115,
        energyGoal: 1460,
        energyProgress: 0.764,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 780,
        exerciseGoal: 1800,
        exerciseProgress: 0.4333
      },
      {
        DATE: "2018-08-20",
        energy: 1340,
        energyGoal: 1460,
        energyProgress: 0.9177,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1320,
        exerciseGoal: 1800,
        exerciseProgress: 0.7333
      },
      {
        DATE: "2018-08-21",
        energy: 1939,
        energyGoal: 1460,
        energyProgress: 1.328,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-08-22",
        energy: 873,
        energyGoal: 1460,
        energyProgress: 0.5982,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 240,
        exerciseGoal: 1800,
        exerciseProgress: 0.1333
      },
      {
        DATE: "2018-08-23",
        energy: 1458,
        energyGoal: 1460,
        energyProgress: 0.9985,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-08-24",
        energy: 2322,
        energyGoal: 1460,
        energyProgress: 1.5901,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 3480,
        exerciseGoal: 1800,
        exerciseProgress: 1.9333
      },
      {
        DATE: "2018-08-25",
        energy: 3101,
        energyGoal: 1460,
        energyProgress: 2.124,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 6780,
        exerciseGoal: 1800,
        exerciseProgress: 3.7667
      },
      {
        DATE: "2018-08-26",
        energy: 3514,
        energyGoal: 1460,
        energyProgress: 2.4069,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 7920,
        exerciseGoal: 1800,
        exerciseProgress: 4.4
      },
      {
        DATE: "2018-08-27",
        energy: 2262,
        energyGoal: 1460,
        energyProgress: 1.5495,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 3120,
        exerciseGoal: 1800,
        exerciseProgress: 1.7333
      },
      {
        DATE: "2018-08-28",
        energy: 1857,
        energyGoal: 1460,
        energyProgress: 1.272,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2220,
        exerciseGoal: 1800,
        exerciseProgress: 1.2333
      },
      {
        DATE: "2018-08-29",
        energy: 1465,
        energyGoal: 1460,
        energyProgress: 1.0037,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-08-30",
        energy: 1833,
        energyGoal: 1460,
        energyProgress: 1.2558,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1680,
        exerciseGoal: 1800,
        exerciseProgress: 0.9333
      },
      {
        DATE: "2018-08-31",
        energy: 1932,
        energyGoal: 1460,
        energyProgress: 1.3235,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2820,
        exerciseGoal: 1800,
        exerciseProgress: 1.5667
      },
      {
        DATE: "2018-09-01",
        energy: 4843,
        energyGoal: 1460,
        energyProgress: 3.3175,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 6960,
        exerciseGoal: 1800,
        exerciseProgress: 3.8667
      },
      {
        DATE: "2018-09-02",
        energy: 4657,
        energyGoal: 1460,
        energyProgress: 3.1898,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 6420,
        exerciseGoal: 1800,
        exerciseProgress: 3.5667
      },
      {
        DATE: "2018-09-03",
        energy: 1545,
        energyGoal: 1460,
        energyProgress: 1.0584,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1680,
        exerciseGoal: 1800,
        exerciseProgress: 0.9333
      },
      {
        DATE: "2018-09-04",
        energy: 1756,
        energyGoal: 1460,
        energyProgress: 1.2027,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1800,
        exerciseGoal: 1800,
        exerciseProgress: 1
      },
      {
        DATE: "2018-09-05",
        energy: 1705,
        energyGoal: 1460,
        energyProgress: 1.1676,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-09-06",
        energy: 1887,
        energyGoal: 1460,
        energyProgress: 1.2924,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-09-07",
        energy: 2011,
        energyGoal: 1460,
        energyProgress: 1.3772,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 2520,
        exerciseGoal: 1800,
        exerciseProgress: 1.4
      },
      {
        DATE: "2018-09-08",
        energy: 4961,
        energyGoal: 1460,
        energyProgress: 3.398,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 6240,
        exerciseGoal: 1800,
        exerciseProgress: 3.4667
      },
      {
        DATE: "2018-09-09",
        energy: 1760,
        energyGoal: 1460,
        energyProgress: 1.2057,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2100,
        exerciseGoal: 1800,
        exerciseProgress: 1.1667
      },
      {
        DATE: "2018-09-10",
        energy: 1594,
        energyGoal: 1460,
        energyProgress: 1.0915,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1740,
        exerciseGoal: 1800,
        exerciseProgress: 0.9667
      },
      {
        DATE: "2018-09-11",
        energy: 1391,
        energyGoal: 1460,
        energyProgress: 0.9528,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 900,
        exerciseGoal: 1800,
        exerciseProgress: 0.5
      },
      {
        DATE: "2018-09-12",
        energy: 1446,
        energyGoal: 1460,
        energyProgress: 0.9905,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-09-13",
        energy: 1457,
        energyGoal: 1460,
        energyProgress: 0.9982,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-09-14",
        energy: 1393,
        energyGoal: 1460,
        energyProgress: 0.9538,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-09-15",
        energy: 2165,
        energyGoal: 1460,
        energyProgress: 1.483,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2700,
        exerciseGoal: 1800,
        exerciseProgress: 1.5
      },
      {
        DATE: "2018-09-16",
        energy: 885,
        energyGoal: 1460,
        energyProgress: 0.6063,
        stand: 10,
        standGoal: 12,
        standProgress: 0.8333,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-09-17",
        energy: 1649,
        energyGoal: 1460,
        energyProgress: 1.1295,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2400,
        exerciseGoal: 1800,
        exerciseProgress: 1.3333
      },
      {
        DATE: "2018-09-18",
        energy: 1274,
        energyGoal: 1460,
        energyProgress: 0.8723,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1320,
        exerciseGoal: 1800,
        exerciseProgress: 0.7333
      },
      {
        DATE: "2018-09-19",
        energy: 2342,
        energyGoal: 1460,
        energyProgress: 1.6042,
        stand: 18,
        standGoal: 12,
        standProgress: 1.5,
        exercise: 2220,
        exerciseGoal: 1800,
        exerciseProgress: 1.2333
      },
      {
        DATE: "2018-09-20",
        energy: 1997,
        energyGoal: 1460,
        energyProgress: 1.3676,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2460,
        exerciseGoal: 1800,
        exerciseProgress: 1.3667
      },
      {
        DATE: "2018-09-21",
        energy: 3797,
        energyGoal: 1460,
        energyProgress: 2.6004,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 5460,
        exerciseGoal: 1800,
        exerciseProgress: 3.0333
      },
      {
        DATE: "2018-09-22",
        energy: 1654,
        energyGoal: 1460,
        energyProgress: 1.1331,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1920,
        exerciseGoal: 1800,
        exerciseProgress: 1.0667
      },
      {
        DATE: "2018-09-23",
        energy: 2291,
        energyGoal: 1460,
        energyProgress: 1.5689,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2940,
        exerciseGoal: 1800,
        exerciseProgress: 1.6333
      },
      {
        DATE: "2018-09-24",
        energy: 923,
        energyGoal: 1460,
        energyProgress: 0.6323,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 240,
        exerciseGoal: 1800,
        exerciseProgress: 0.1333
      },
      {
        DATE: "2018-09-25",
        energy: 776,
        energyGoal: 1460,
        energyProgress: 0.5313,
        stand: 10,
        standGoal: 12,
        standProgress: 0.8333,
        exercise: 180,
        exerciseGoal: 1800,
        exerciseProgress: 0.1
      },
      {
        DATE: "2018-09-26",
        energy: 2621,
        energyGoal: 1460,
        energyProgress: 1.7949,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 3720,
        exerciseGoal: 1800,
        exerciseProgress: 2.0667
      },
      {
        DATE: "2018-09-27",
        energy: 2447,
        energyGoal: 1460,
        energyProgress: 1.6762,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 3780,
        exerciseGoal: 1800,
        exerciseProgress: 2.1
      },
      {
        DATE: "2018-09-28",
        energy: 2912,
        energyGoal: 1460,
        energyProgress: 1.9947,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 4140,
        exerciseGoal: 1800,
        exerciseProgress: 2.3
      },
      {
        DATE: "2018-09-29",
        energy: 2201,
        energyGoal: 1460,
        energyProgress: 1.5078,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2340,
        exerciseGoal: 1800,
        exerciseProgress: 1.3
      },
      {
        DATE: "2018-09-30",
        energy: 3080,
        energyGoal: 1460,
        energyProgress: 2.1098,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 4740,
        exerciseGoal: 1800,
        exerciseProgress: 2.6333
      },
      {
        DATE: "2018-10-01",
        energy: 1939,
        energyGoal: 1460,
        energyProgress: 1.3283,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-10-02",
        energy: 1592,
        energyGoal: 1460,
        energyProgress: 1.0905,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 900,
        exerciseGoal: 1800,
        exerciseProgress: 0.5
      },
      {
        DATE: "2018-10-03",
        energy: 2471,
        energyGoal: 1460,
        energyProgress: 1.6924,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-10-04",
        energy: 1517,
        energyGoal: 1460,
        energyProgress: 1.0388,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-10-05",
        energy: 2513,
        energyGoal: 1460,
        energyProgress: 1.721,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-10-06",
        energy: 1123,
        energyGoal: 1460,
        energyProgress: 0.7692,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 660,
        exerciseGoal: 1800,
        exerciseProgress: 0.3667
      },
      {
        DATE: "2018-10-07",
        energy: 1257,
        energyGoal: 1460,
        energyProgress: 0.8608,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 780,
        exerciseGoal: 1800,
        exerciseProgress: 0.4333
      },
      {
        DATE: "2018-10-08",
        energy: 1781,
        energyGoal: 1460,
        energyProgress: 1.22,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1740,
        exerciseGoal: 1800,
        exerciseProgress: 0.9667
      },
      {
        DATE: "2018-10-09",
        energy: 3163,
        energyGoal: 1460,
        energyProgress: 2.1666,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 4320,
        exerciseGoal: 1800,
        exerciseProgress: 2.4
      },
      {
        DATE: "2018-10-10",
        energy: 1632,
        energyGoal: 1460,
        energyProgress: 1.1181,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-10-11",
        energy: 1604,
        energyGoal: 1460,
        energyProgress: 1.0989,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-10-12",
        energy: 1857,
        energyGoal: 1460,
        energyProgress: 1.2716,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-10-13",
        energy: 1213,
        energyGoal: 1460,
        energyProgress: 0.831,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-10-14",
        energy: 1149,
        energyGoal: 1460,
        energyProgress: 0.7873,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-10-15",
        energy: 1728,
        energyGoal: 1460,
        energyProgress: 1.1835,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2040,
        exerciseGoal: 1800,
        exerciseProgress: 1.1333
      },
      {
        DATE: "2018-10-16",
        energy: 1920,
        energyGoal: 1460,
        energyProgress: 1.3152,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-10-17",
        energy: 1855,
        energyGoal: 1460,
        energyProgress: 1.2704,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 900,
        exerciseGoal: 1800,
        exerciseProgress: 0.5
      },
      {
        DATE: "2018-10-18",
        energy: 2391,
        energyGoal: 1460,
        energyProgress: 1.6378,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2940,
        exerciseGoal: 1800,
        exerciseProgress: 1.6333
      },
      {
        DATE: "2018-10-19",
        energy: 2747,
        energyGoal: 1460,
        energyProgress: 1.8813,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 3180,
        exerciseGoal: 1800,
        exerciseProgress: 1.7667
      },
      {
        DATE: "2018-10-20",
        energy: 1886,
        energyGoal: 1460,
        energyProgress: 1.2919,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2340,
        exerciseGoal: 1800,
        exerciseProgress: 1.3
      },
      {
        DATE: "2018-10-21",
        energy: 2178,
        energyGoal: 1460,
        energyProgress: 1.4919,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1920,
        exerciseGoal: 1800,
        exerciseProgress: 1.0667
      },
      {
        DATE: "2018-10-22",
        energy: 2027,
        energyGoal: 1460,
        energyProgress: 1.3881,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-10-23",
        energy: 1791,
        energyGoal: 1460,
        energyProgress: 1.2267,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-10-24",
        energy: 3493,
        energyGoal: 1460,
        energyProgress: 2.3923,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 4620,
        exerciseGoal: 1800,
        exerciseProgress: 2.5667
      },
      {
        DATE: "2018-10-25",
        energy: 1566,
        energyGoal: 1460,
        energyProgress: 1.0725,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-10-26",
        energy: 2164,
        energyGoal: 1460,
        energyProgress: 1.4822,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 3000,
        exerciseGoal: 1800,
        exerciseProgress: 1.6667
      },
      {
        DATE: "2018-10-27",
        energy: 2702,
        energyGoal: 1460,
        energyProgress: 1.8509,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 4500,
        exerciseGoal: 1800,
        exerciseProgress: 2.5
      },
      {
        DATE: "2018-10-28",
        energy: 1310,
        energyGoal: 1460,
        energyProgress: 0.897,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 60,
        exerciseGoal: 1800,
        exerciseProgress: 0.0333
      },
      {
        DATE: "2018-10-29",
        energy: 1663,
        energyGoal: 1460,
        energyProgress: 1.1389,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1440,
        exerciseGoal: 1800,
        exerciseProgress: 0.8
      },
      {
        DATE: "2018-10-30",
        energy: 1785,
        energyGoal: 1460,
        energyProgress: 1.2225,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-10-31",
        energy: 1693,
        energyGoal: 1460,
        energyProgress: 1.1596,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2340,
        exerciseGoal: 1800,
        exerciseProgress: 1.3
      },
      {
        DATE: "2018-11-01",
        energy: 1408,
        energyGoal: 1460,
        energyProgress: 0.9642,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 660,
        exerciseGoal: 1800,
        exerciseProgress: 0.3667
      },
      {
        DATE: "2018-11-02",
        energy: 2040,
        energyGoal: 1460,
        energyProgress: 1.3972,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 1680,
        exerciseGoal: 1800,
        exerciseProgress: 0.9333
      },
      {
        DATE: "2018-11-03",
        energy: 2161,
        energyGoal: 1460,
        energyProgress: 1.4799,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1980,
        exerciseGoal: 1800,
        exerciseProgress: 1.1
      },
      {
        DATE: "2018-11-04",
        energy: 1790,
        energyGoal: 1460,
        energyProgress: 1.2258,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-11-05",
        energy: 2596,
        energyGoal: 1460,
        energyProgress: 1.7778,
        stand: 18,
        standGoal: 12,
        standProgress: 1.5,
        exercise: 4980,
        exerciseGoal: 1800,
        exerciseProgress: 2.7667
      },
      {
        DATE: "2018-11-06",
        energy: 2859,
        energyGoal: 1460,
        energyProgress: 1.9582,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 4140,
        exerciseGoal: 1800,
        exerciseProgress: 2.3
      },
      {
        DATE: "2018-11-07",
        energy: 2355,
        energyGoal: 1460,
        energyProgress: 1.6128,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 3360,
        exerciseGoal: 1800,
        exerciseProgress: 1.8667
      },
      {
        DATE: "2018-11-08",
        energy: 1952,
        energyGoal: 1460,
        energyProgress: 1.3367,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1980,
        exerciseGoal: 1800,
        exerciseProgress: 1.1
      },
      {
        DATE: "2018-11-09",
        energy: 1846,
        energyGoal: 1460,
        energyProgress: 1.2641,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 1740,
        exerciseGoal: 1800,
        exerciseProgress: 0.9667
      },
      {
        DATE: "2018-11-10",
        energy: 2024,
        energyGoal: 1460,
        energyProgress: 1.3865,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-11-11",
        energy: 3147,
        energyGoal: 1460,
        energyProgress: 2.1553,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 4020,
        exerciseGoal: 1800,
        exerciseProgress: 2.2333
      },
      {
        DATE: "2018-11-12",
        energy: 1920,
        energyGoal: 1460,
        energyProgress: 1.3154,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 1380,
        exerciseGoal: 1800,
        exerciseProgress: 0.7667
      },
      {
        DATE: "2018-11-13",
        energy: 1367,
        energyGoal: 1460,
        energyProgress: 0.9364,
        stand: 10,
        standGoal: 12,
        standProgress: 0.8333,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-11-14",
        energy: 2481,
        energyGoal: 1460,
        energyProgress: 1.6994,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 1920,
        exerciseGoal: 1800,
        exerciseProgress: 1.0667
      },
      {
        DATE: "2018-11-15",
        energy: 1815,
        energyGoal: 1460,
        energyProgress: 1.2434,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1260,
        exerciseGoal: 1800,
        exerciseProgress: 0.7
      },
      {
        DATE: "2018-11-16",
        energy: 2448,
        energyGoal: 1460,
        energyProgress: 1.6764,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-11-17",
        energy: 3221,
        energyGoal: 1460,
        energyProgress: 2.206,
        stand: 19,
        standGoal: 12,
        standProgress: 1.5833,
        exercise: 3540,
        exerciseGoal: 1800,
        exerciseProgress: 1.9667
      },
      {
        DATE: "2018-11-18",
        energy: 1888,
        energyGoal: 1460,
        energyProgress: 1.2931,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 480,
        exerciseGoal: 1800,
        exerciseProgress: 0.2667
      },
      {
        DATE: "2018-11-19",
        energy: 1746,
        energyGoal: 1460,
        energyProgress: 1.1962,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 840,
        exerciseGoal: 1800,
        exerciseProgress: 0.4667
      },
      {
        DATE: "2018-11-20",
        energy: 1532,
        energyGoal: 1460,
        energyProgress: 1.049,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-11-21",
        energy: 2106,
        energyGoal: 1460,
        energyProgress: 1.4428,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-11-22",
        energy: 1079,
        energyGoal: 1460,
        energyProgress: 0.7387,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 240,
        exerciseGoal: 1800,
        exerciseProgress: 0.1333
      },
      {
        DATE: "2018-11-23",
        energy: 3538,
        energyGoal: 1460,
        energyProgress: 2.423,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 6000,
        exerciseGoal: 1800,
        exerciseProgress: 3.3333
      },
      {
        DATE: "2018-11-24",
        energy: 1839,
        energyGoal: 1460,
        energyProgress: 1.2597,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2160,
        exerciseGoal: 1800,
        exerciseProgress: 1.2
      },
      {
        DATE: "2018-11-25",
        energy: 1578,
        energyGoal: 1460,
        energyProgress: 1.081,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1320,
        exerciseGoal: 1800,
        exerciseProgress: 0.7333
      },
      {
        DATE: "2018-11-26",
        energy: 1622,
        energyGoal: 1460,
        energyProgress: 1.1107,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-11-27",
        energy: 3127,
        energyGoal: 1460,
        energyProgress: 2.1419,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 4440,
        exerciseGoal: 1800,
        exerciseProgress: 2.4667
      },
      {
        DATE: "2018-11-28",
        energy: 1909,
        energyGoal: 1460,
        energyProgress: 1.3075,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1980,
        exerciseGoal: 1800,
        exerciseProgress: 1.1
      },
      {
        DATE: "2018-11-29",
        energy: 1548,
        energyGoal: 1460,
        energyProgress: 1.0604,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1320,
        exerciseGoal: 1800,
        exerciseProgress: 0.7333
      },
      {
        DATE: "2018-11-30",
        energy: 2063,
        energyGoal: 1460,
        energyProgress: 1.4127,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1980,
        exerciseGoal: 1800,
        exerciseProgress: 1.1
      },
      {
        DATE: "2018-12-01",
        energy: 1229,
        energyGoal: 1460,
        energyProgress: 0.8417,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 480,
        exerciseGoal: 1800,
        exerciseProgress: 0.2667
      },
      {
        DATE: "2018-12-02",
        energy: 2295,
        energyGoal: 1460,
        energyProgress: 1.5719,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2820,
        exerciseGoal: 1800,
        exerciseProgress: 1.5667
      },
      {
        DATE: "2018-12-03",
        energy: 2427,
        energyGoal: 1460,
        energyProgress: 1.6624,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2220,
        exerciseGoal: 1800,
        exerciseProgress: 1.2333
      },
      {
        DATE: "2018-12-04",
        energy: 1688,
        energyGoal: 1460,
        energyProgress: 1.156,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1020,
        exerciseGoal: 1800,
        exerciseProgress: 0.5667
      },
      {
        DATE: "2018-12-05",
        energy: 1909,
        energyGoal: 1460,
        energyProgress: 1.3078,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 960,
        exerciseGoal: 1800,
        exerciseProgress: 0.5333
      },
      {
        DATE: "2018-12-06",
        energy: 1604,
        energyGoal: 1460,
        energyProgress: 1.0986,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1320,
        exerciseGoal: 1800,
        exerciseProgress: 0.7333
      },
      {
        DATE: "2018-12-07",
        energy: 2160,
        energyGoal: 1460,
        energyProgress: 1.4795,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1980,
        exerciseGoal: 1800,
        exerciseProgress: 1.1
      },
      {
        DATE: "2018-12-08",
        energy: 1560,
        energyGoal: 1460,
        energyProgress: 1.0688,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 360,
        exerciseGoal: 1800,
        exerciseProgress: 0.2
      },
      {
        DATE: "2018-12-09",
        energy: 1549,
        energyGoal: 1460,
        energyProgress: 1.0608,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1080,
        exerciseGoal: 1800,
        exerciseProgress: 0.6
      },
      {
        DATE: "2018-12-10",
        energy: 1903,
        energyGoal: 1460,
        energyProgress: 1.3037,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1500,
        exerciseGoal: 1800,
        exerciseProgress: 0.8333
      },
      {
        DATE: "2018-12-11",
        energy: 1612,
        energyGoal: 1460,
        energyProgress: 1.1039,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 1200,
        exerciseGoal: 1800,
        exerciseProgress: 0.6667
      },
      {
        DATE: "2018-12-12",
        energy: 2344,
        energyGoal: 1460,
        energyProgress: 1.6053,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2580,
        exerciseGoal: 1800,
        exerciseProgress: 1.4333
      },
      {
        DATE: "2018-12-13",
        energy: 1882,
        energyGoal: 1460,
        energyProgress: 1.2889,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 1500,
        exerciseGoal: 1800,
        exerciseProgress: 0.8333
      },
      {
        DATE: "2018-12-14",
        energy: 2715,
        energyGoal: 1460,
        energyProgress: 1.8594,
        stand: 16,
        standGoal: 12,
        standProgress: 1.3333,
        exercise: 2760,
        exerciseGoal: 1800,
        exerciseProgress: 1.5333
      },
      {
        DATE: "2018-12-15",
        energy: 2881,
        energyGoal: 1460,
        energyProgress: 1.9733,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 3600,
        exerciseGoal: 1800,
        exerciseProgress: 2
      },
      {
        DATE: "2018-12-16",
        energy: 2276,
        energyGoal: 1460,
        energyProgress: 1.5586,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 1800,
        exerciseGoal: 1800,
        exerciseProgress: 1
      },
      {
        DATE: "2018-12-17",
        energy: 2348,
        energyGoal: 1460,
        energyProgress: 1.608,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 1980,
        exerciseGoal: 1800,
        exerciseProgress: 1.1
      },
      {
        DATE: "2018-12-18",
        energy: 2812,
        energyGoal: 1460,
        energyProgress: 1.926,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 3840,
        exerciseGoal: 1800,
        exerciseProgress: 2.1333
      },
      {
        DATE: "2018-12-19",
        energy: 1626,
        energyGoal: 1460,
        energyProgress: 1.1135,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 660,
        exerciseGoal: 1800,
        exerciseProgress: 0.3667
      },
      {
        DATE: "2018-12-20",
        energy: 2951,
        energyGoal: 1460,
        energyProgress: 2.0214,
        stand: 17,
        standGoal: 12,
        standProgress: 1.4167,
        exercise: 2640,
        exerciseGoal: 1800,
        exerciseProgress: 1.4667
      },
      {
        DATE: "2018-12-21",
        energy: 2145,
        energyGoal: 1460,
        energyProgress: 1.469,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1560,
        exerciseGoal: 1800,
        exerciseProgress: 0.8667
      },
      {
        DATE: "2018-12-22",
        energy: 2383,
        energyGoal: 1460,
        energyProgress: 1.6321,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2340,
        exerciseGoal: 1800,
        exerciseProgress: 1.3
      },
      {
        DATE: "2018-12-23",
        energy: 1510,
        energyGoal: 1460,
        energyProgress: 1.0342,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 360,
        exerciseGoal: 1800,
        exerciseProgress: 0.2
      },
      {
        DATE: "2018-12-24",
        energy: 2047,
        energyGoal: 1460,
        energyProgress: 1.4023,
        stand: 11,
        standGoal: 12,
        standProgress: 0.9167,
        exercise: 1620,
        exerciseGoal: 1800,
        exerciseProgress: 0.9
      },
      {
        DATE: "2018-12-25",
        energy: 1831,
        energyGoal: 1460,
        energyProgress: 1.2543,
        stand: 12,
        standGoal: 12,
        standProgress: 1,
        exercise: 840,
        exerciseGoal: 1800,
        exerciseProgress: 0.4667
      },
      {
        DATE: "2018-12-26",
        energy: 2339,
        energyGoal: 1460,
        energyProgress: 1.6022,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 2340,
        exerciseGoal: 1800,
        exerciseProgress: 1.3
      },
      {
        DATE: "2018-12-27",
        energy: 2746,
        energyGoal: 1460,
        energyProgress: 1.8807,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 4740,
        exerciseGoal: 1800,
        exerciseProgress: 2.6333
      },
      {
        DATE: "2018-12-28",
        energy: 2010,
        energyGoal: 1460,
        energyProgress: 1.3769,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 2820,
        exerciseGoal: 1800,
        exerciseProgress: 1.5667
      },
      {
        DATE: "2018-12-29",
        energy: 2045,
        energyGoal: 1460,
        energyProgress: 1.4004,
        stand: 15,
        standGoal: 12,
        standProgress: 1.25,
        exercise: 1140,
        exerciseGoal: 1800,
        exerciseProgress: 0.6333
      },
      {
        DATE: "2018-12-30",
        energy: 1318,
        energyGoal: 1460,
        energyProgress: 0.9027,
        stand: 13,
        standGoal: 12,
        standProgress: 1.0833,
        exercise: 660,
        exerciseGoal: 1800,
        exerciseProgress: 0.3667
      },
      {
        DATE: "2018-12-31",
        energy: 2039,
        energyGoal: 1460,
        energyProgress: 1.3965,
        stand: 14,
        standGoal: 12,
        standProgress: 1.1667,
        exercise: 2220,
        exerciseGoal: 1800,
        exerciseProgress: 1.2333
      }
    ]
  },
  {
    type: "Asside",
    content:
      "This year was rather light with regards to travel compared to previous years. There were only two international trips, both to the US west coast, and one trip to Melbourne.",
    title: "Travel",
    key: uuidv1()
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        number: 3902,
        prefix: "",
        suffix: "",
        title: "Time in the air",
        unit: "Min",
        figuretext: "Almost a whole day less than last year",
        infotext: "My longest flight was Sydney to Houston, at _15 hr 11 min_"
      },
      {
        key: uuidv1(),
        number: 54859,
        prefix: "",
        suffix: "",
        title: "Distance flown",
        unit: "KM",
        figuretext:
          "Across _10 flights_, the most common being Los Angeles to Sydney",
        infotext:
          "With a standard deviation of _5,757_, I only flew extremely long and extremely short flights"
      }
    ],
    key: uuidv1()
  },
  {
    type: "BasicMap",
    key: uuidv1()
  },
  {
    type: "Asside",
    content:
      "2018 consisted of two ski resorts, Telluride CO, and Thredbo Australia. While not my most vertical metres, or most days skiing, this year did bring my fastest top speed, and boy was it scary.",
    title: "Skiing",
    key: uuidv1()
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        number: 46648,
        prefix: "",
        suffix: "",
        title: "Skiing Vertical",
        unit: "metres",
        figuretext:
          "Over a horizontal distance of _266km_, across _135_ ski runs",
        infotext: "With a peak altitude of _3730_ metres"
      },
      {
        key: uuidv1(),
        number: 112,
        prefix: "",
        suffix: "",
        title: "Maximum Speed",
        unit: "km/h",
        figuretext: "An _11%_ increase over last year",
        infotext: ""
      }
    ],
    key: uuidv1()
  },
  {
    key: uuidv1(),
    type: "SlopesMap",
    url: "slopes/Thredbo Alpine Resort.csv",
    scale: 2000000,
    title: "Thredbo Resort, Australia (July/August)"
  },
  {
    key: uuidv1(),
    type: "SlopesMap",
    url: "slopes/Telluride Ski Resort.csv",
    scale: 1000000,
    title: "Telluride Resort, CO—USA (February)"
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        number: 881,
        prefix: "",
        suffix: "",
        title: "Total Time on Chairlifts",
        unit: "min",
        figuretext:
          "The fastest chairlift travelled at _32 km/h_, and the slowest chairlift travelled at an average of _8 km/h_",
        infotext: "The longest time on a chairlift was _15 min_"
      },
      {
        key: uuidv1(),
        number: 177,
        prefix: "",
        suffix: "",
        title: "Total Distance on Chairlifts",
        unit: "km",
        figuretext: "The longest chairlift was _3,198_ metres",
        infotext: ""
      }
    ],
    key: uuidv1()
  },
  {
    type: "Asside",
    content:
      "During the year I ran a custom location tracker, below is a heatmap of everywhere I went during the year (colour representing how long I spent in that place cumulative). Zoom and pan around to explore where I went in Australia and the USA, even down to the street level.",
    title: "Movement",
    key: uuidv1()
  },
  {
    type: "Slider",
    key: uuidv1(),
    items: [
        {
            original: "maps/originals/map_00000.jpeg",
            thumbnail: "maps/thumbnails/map_00000.jpeg"
        },
        {
            original: "maps/originals/map_00001.jpeg",
            thumbnail: "maps/thumbnails/map_00001.jpeg"
        },
        {
            original: "maps/originals/map_00002.jpeg",
            thumbnail: "maps/thumbnails/map_00002.jpeg"
        },
        {
            original: "maps/originals/map_00003.jpeg",
            thumbnail: "maps/thumbnails/map_00003.jpeg"
        },
        {
            original: "maps/originals/map_00004.jpeg",
            thumbnail: "maps/thumbnails/map_00004.jpeg"
        },
        {
            original: "maps/originals/map_00005.jpeg",
            thumbnail: "maps/thumbnails/map_00005.jpeg"
        },
        {
            original: "maps/originals/map_00006.jpeg",
            thumbnail: "maps/thumbnails/map_00006.jpeg"
        },
        {
            original: "maps/originals/map_00007.jpeg",
            thumbnail: "maps/thumbnails/map_00007.jpeg"
        },
        {
            original: "maps/originals/map_00008.jpeg",
            thumbnail: "maps/thumbnails/map_00008.jpeg"
        },
        {
            original: "maps/originals/map_00009.jpeg",
            thumbnail: "maps/thumbnails/map_00009.jpeg"
        },
        {
            original: "maps/originals/map_00010.jpeg",
            thumbnail: "maps/thumbnails/map_00010.jpeg"
        },
        {
            original: "maps/originals/map_00011.jpeg",
            thumbnail: "maps/thumbnails/map_00011.jpeg"
        },
        {
            original: "maps/originals/map_00012.jpeg",
            thumbnail: "maps/thumbnails/map_00012.jpeg"
        },
        {
            original: "maps/originals/map_00013.jpeg",
            thumbnail: "maps/thumbnails/map_00013.jpeg"
        },
        {
            original: "maps/originals/map_00014.jpeg",
            thumbnail: "maps/thumbnails/map_00014.jpeg"
        },
    ]
  },
  {
    type: "Asside",
    content:
      "The first Year in Numbers was nothing but music, so it is only fitting that music makes up a significant portion of my life.",
    title: "Music",
    key: uuidv1()
  },

  {
    type: "BubbleMatrix",
    key: uuidv1(),
    title: "Playing Time by Hour of Day (and day of week)",
    unit: "Plays",
    columns: 24,
    rows: 7,
    noToolTip: true,
    color: "var(--accent-color)",
    row_titles: ["MON", "", "", "", "", "", "SUN"],
    column_titles: [
      {
        title: "Night",
        columns: 6.5
      },
      {
        title: "Morning",
        columns: 6.2
      },
      {
        title: "Afternoon",
        columns: 6
      },
      {
        title: "Evening",
        columns: 6
      }
    ],
    data: [
      [
        1083487,
        449584,
        1054800,
        5091128,
        1620615,
        1937305,
        6245993,
        12157874,
        27229743,
        26609234,
        34783792,
        44211809,
        4631766,
        38989681,
        48036994,
        51831391,
        28568645,
        26955331,
        27946369,
        33018778,
        14328700,
        17892184,
        11846325,
        4242463
      ],
      [
        179908,
        255604,
        0,
        0,
        0,
        0,
        4351188,
        15507663,
        20033946,
        25380334,
        36077569,
        45764734,
        7543499,
        39719166,
        57404186,
        49373274,
        31235525,
        23386464,
        35992939,
        34427771,
        25084862,
        20258525,
        20125353,
        5238192
      ],
      [
        657893,
        0,
        0,
        0,
        0,
        190032,
        2659532,
        18292371,
        25238956,
        20284010,
        30414678,
        35725673,
        4147893,
        41879225,
        37594343,
        47012096,
        30198046,
        32635566,
        41263965,
        31229473,
        25311795,
        16514438,
        6845777,
        3900886
      ],
      [
        596287,
        0,
        0,
        0,
        0,
        0,
        3933176,
        14948913,
        24370631,
        19551432,
        50417525,
        46540111,
        6542112,
        44099926,
        50029554,
        50181647,
        31239159,
        20972631,
        26546241,
        32210186,
        11586568,
        7167349,
        6030025,
        4082764
      ],
      [
        2008897,
        423303,
        0,
        0,
        0,
        0,
        2686029,
        23220363,
        37069750,
        22184269,
        35468802,
        32951580,
        3318712,
        35092265,
        51999387,
        43482265,
        31112825,
        27228868,
        32578643,
        18564361,
        10866848,
        5635502,
        11094248,
        7527334
      ],
      [
        1510086,
        0,
        320729,
        0,
        0,
        0,
        2446503,
        4119034,
        16628466,
        22661038,
        5575967,
        20083213,
        30003940,
        18055922,
        14240620,
        17646119,
        14192882,
        24855796,
        17113937,
        12921591,
        10894446,
        8102374,
        11162065,
        6262017
      ],
      [
        1301168,
        0,
        0,
        0,
        0,
        0,
        655618,
        6819163,
        8033505,
        16367897,
        11216931,
        22177631,
        20092186,
        21694461,
        20304504,
        19507362,
        20072209,
        29441885,
        17606855,
        17724388,
        8781949,
        7380786,
        4799326,
        4638853
      ]
    ]
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        number: 47050,
        prefix: "",
        suffix: "",
        title: "Time listening to music",
        unit: "min",
        figuretext:
          "A _23%_ increase over 2017 with over _32 days_ of continuous listening",
        infotext: ""
      },
      {
        key: uuidv1(),
        number: 4856,
        prefix: "",
        suffix: "",
        title: "Played songs",
        unit: "total",
        figuretext:
          "Adding _1,412_ new songs to my library, a _49%_ increase over last year",
        infotext: ""
      }
    ],
    key: uuidv1()
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        noCount: true,
        number: 11,
        prefix: "Sept ",
        suffix: "",
        title: "Most Time",
        unit: "listening",
        figuretext: "With _7h 22m 19s_ of music, ",
        infotext: ""
      },
      {
        key: uuidv1(),
        number: 1699,
        prefix: "",
        suffix: "",
        title: "Played artists",
        unit: "total",
        figuretext: "",
        infotext: ""
      }
    ],
    key: uuidv1()
  },
  {
    type: "InfoBoxes",
    content: "",
    title: "Most played artist",
    headline: "artist",
    subtitleString: function(box) {
      return `__${numeral(box.total_plays).format(
        "0,0"
      )}__ plays over __${convertTime(box.total_duration)}__`;
    },
    bodyString: function(box) {
      return `With '__${box.top_song}__' played _${numeral(
        box.top_song_plays
      ).format("0,0")}_ times, over _${convertTime(box.top_song_duration)}_`;
    },
    boxes: [
      {
        artist: "Sufjan Stevens",
        top_song: "Visions of Gideon",
        top_song_duration: 23621852,
        top_song_plays: 102,
        total_duration: 105023610,
        total_plays: 456
      },
      {
        artist: "Radical Face",
        top_song: "Always Gold",
        top_song_duration: 13405528,
        top_song_plays: 44,
        total_duration: 76453345,
        total_plays: 358
      },
      {
        artist: "Mat Kearney",
        top_song: "Heartbreak Dreamer",
        top_song_duration: 9096452,
        top_song_plays: 32,
        total_duration: 74448653,
        total_plays: 347
      },
      {
        artist: "Roo Panes",
        top_song: "Tiger Striped Sky",
        top_song_duration: 7630198,
        top_song_plays: 37,
        total_duration: 66227646,
        total_plays: 282
      },
      {
        artist: "Coldplay",
        top_song: "Fix You",
        top_song_duration: 4029039,
        top_song_plays: 15,
        total_duration: 60986816,
        total_plays: 294
      },
      {
        artist: "WILD",
        top_song: "Throw Me in the Water",
        top_song_duration: 11212853,
        top_song_plays: 60,
        total_duration: 48486214,
        total_plays: 251
      },
      {
        artist: "Matt and Kim",
        top_song: "Like I Used To Be",
        top_song_duration: 9830380,
        top_song_plays: 43,
        total_duration: 44512409,
        total_plays: 270
      },
      {
        artist: "Jim Guthrie",
        top_song: "Trust",
        top_song_duration: 3490374,
        top_song_plays: 14,
        total_duration: 42392884,
        total_plays: 258
      }
    ],
    key: uuidv1()
  },
  {
    type: "Embed",
    key: uuidv1(),
    iframe: {
      allow: "autoplay *; encrypted-media *;",
      frameBorder: "0",
      height: 450,
      style: { overflow: "hidden", background: "transparent" },
      sandbox:
        "allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation",
      src:
        "https://embed.music.apple.com/au/playlist/2018-top-10-songs/pl.u-02v3uWxKm5a"
    }
  },
  {
    type: "Asside",
    content:
      "This year I decided to look at how I message people. Primarily, when and what I say.",
    title: "Communication",
    key: uuidv1()
  },
  {
    type: "Passage",
    content:
      'Below are some of my most frequently said words compared to their counterparts (grouped for similar sentiment and alternative spellings; e.g., "pls" and "please") sorted from most to least used.\n\n__I seemed to _know_ more than _idk_, _smile_ more than _frown_, and talk about _today_ more than _tomorrow_...__',
    key: uuidv1()
  },
  {
    type: "CompareTwoList",
    key: uuidv1(),
    components: [
      {
        left: ":)",
        leftCount: 1482,
        right: ":(",
        rightCount: 860
      },
      {
        left: "feel",
        leftCount: 366,
        right: "think",
        rightCount: 1228
      },
      {
        left: "know",
        leftCount: 905,
        right: "idk",
        rightCount: 261
      },
      {
        left: "you",
        leftCount: 8679,
        right: "I",
        rightCount: 11766
      },
      {
        left: "morning",
        leftCount: 328,
        right: "night",
        rightCount: 569
      },
      {
        left: "now",
        leftCount: 1066,
        right: "then",
        rightCount: 1015
      },
      {
        left: "no",
        leftCount: 1203,
        right: "yes",
        rightCount: 3104
      },
      {
        left: "person",
        leftCount: 150,
        right: "people",
        rightCount: 683
      },
      {
        left: "please",
        leftCount: 324,
        right: "thanks",
        rightCount: 1212
      },
      {
        left: "aw",
        leftCount: 208,
        right: "awwww",
        rightCount: 118
      },
      {
        left: "why",
        leftCount: 363,
        right: "because",
        rightCount: 592
      },
      {
        left: "going",
        leftCount: 1364,
        right: "coming",
        rightCount: 359
      },
      {
        left: "maybe",
        leftCount: 688,
        right: "definitely",
        rightCount: 129
      },
      {
        left: "same",
        leftCount: 438,
        right: "different",
        rightCount: 128
      },
      {
        left: "give",
        leftCount: 191,
        right: "take",
        rightCount: 317
      },
      {
        left: "when",
        leftCount: 889,
        right: "what",
        rightCount: 1298
      },
      {
        left: "today",
        leftCount: 412,
        right: "tomorrow",
        rightCount: 140
      },
      {
        left: "could",
        leftCount: 476,
        right: "should",
        rightCount: 606
      },
      {
        left: "cuddle",
        leftCount: 116,
        right: "hug",
        rightCount: 1131
      },
      {
        left: "weird",
        leftCount: 137,
        right: "interesting",
        rightCount: 123
      },
      {
        left: "new",
        leftCount: 377,
        right: "old",
        rightCount: 152
      },
      {
        left: "can",
        leftCount: 1301,
        right: "can't",
        rightCount: 273
      },
      {
        left: ":/",
        leftCount: 285,
        right: ":P",
        rightCount: 638
      }
    ]
  },
  {
    type: "Sparkline",
    key: uuidv1(),
    title: "Messages Sent by Day",
    color: "var(--accent-color)",
    data: [
      73,
      51,
      25,
      49,
      42,
      23,
      3,
      107,
      80,
      89,
      76,
      126,
      22,
      17,
      83,
      168,
      177,
      127,
      30,
      38,
      27,
      100,
      21,
      96,
      31,
      1,
      99,
      36,
      2,
      138,
      242,
      229,
      285,
      10,
      34,
      27,
      80,
      43,
      30,
      30,
      13,
      159,
      9,
      50,
      63,
      19,
      4,
      59,
      4,
      26,
      57,
      11,
      29,
      23,
      12,
      12,
      36,
      11,
      23,
      5,
      53,
      33,
      11,
      251,
      15,
      17,
      17,
      5,
      10,
      22,
      20,
      56,
      51,
      72,
      50,
      3,
      156,
      1,
      13,
      72,
      76,
      14,
      10,
      0,
      25,
      48,
      84,
      0,
      7,
      30,
      13,
      17,
      47,
      92,
      73,
      36,
      9,
      10,
      79,
      15,
      25,
      6,
      10,
      18,
      6,
      9,
      23,
      9,
      29,
      23,
      12,
      18,
      72,
      94,
      17,
      45,
      87,
      28,
      7,
      63,
      66,
      13,
      16,
      2,
      25,
      119,
      38,
      51,
      55,
      48,
      126,
      9,
      44,
      47,
      67,
      25,
      83,
      48,
      17,
      12,
      31,
      72,
      156,
      125,
      84,
      137,
      2,
      93,
      13,
      80,
      7,
      76,
      61,
      82,
      276,
      104,
      190,
      138,
      122,
      104,
      394,
      87,
      238,
      229,
      87,
      38,
      38,
      82,
      87,
      133,
      88,
      234,
      253,
      111,
      40,
      172,
      191,
      64,
      75,
      161,
      64,
      190,
      112,
      132,
      122,
      108,
      135,
      54,
      95,
      115,
      83,
      56,
      41,
      182,
      123,
      56,
      89,
      170,
      135,
      56,
      126,
      50,
      88,
      96,
      99,
      77,
      66,
      125,
      146,
      93,
      119,
      79,
      84,
      70,
      100,
      65,
      32,
      106,
      78,
      58,
      91,
      159,
      158,
      46,
      119,
      83,
      119,
      177,
      190,
      25,
      47,
      98,
      133,
      111,
      77,
      108,
      12,
      18,
      28,
      63,
      118,
      105,
      145,
      95,
      122,
      143,
      41,
      83,
      122,
      82,
      143,
      194,
      79,
      109,
      205,
      116,
      162,
      24,
      59,
      88,
      27,
      50,
      50,
      205,
      22,
      84,
      141,
      131,
      111,
      85,
      103,
      1,
      17,
      23,
      88,
      34,
      58,
      100,
      38,
      40,
      107,
      37,
      58,
      87,
      69,
      78,
      134,
      134,
      132,
      34,
      81,
      44,
      97,
      200,
      67,
      105,
      69,
      65,
      44,
      22,
      56,
      51,
      115,
      96,
      119,
      92,
      122,
      142,
      273,
      163,
      323,
      171,
      123,
      22,
      45,
      114,
      21,
      99,
      134,
      108,
      72,
      72,
      154,
      101,
      170,
      59,
      97,
      63,
      113,
      97,
      160,
      108,
      141,
      155,
      43,
      141,
      81,
      107,
      67,
      87,
      87,
      39,
      100,
      133,
      64,
      78,
      102,
      121,
      54,
      39,
      86,
      49,
      221,
      82,
      509,
      832,
      567,
      329,
      409,
      402,
      480,
      253,
      73,
      130,
      37
    ]
  },
  {
    type: "Sparkline",
    key: uuidv1(),
    title: "Messages Received by Day",
    color: "var(--accent-color-2)",
    data: [
      151,
      67,
      24,
      37,
      205,
      124,
      7,
      113,
      108,
      88,
      139,
      165,
      32,
      57,
      90,
      228,
      173,
      125,
      46,
      35,
      30,
      88,
      37,
      76,
      27,
      3,
      125,
      40,
      0,
      114,
      179,
      189,
      228,
      17,
      30,
      27,
      71,
      33,
      19,
      33,
      15,
      170,
      13,
      48,
      65,
      22,
      7,
      55,
      5,
      25,
      65,
      8,
      99,
      31,
      13,
      20,
      67,
      13,
      26,
      18,
      142,
      33,
      1,
      258,
      18,
      13,
      24,
      8,
      8,
      12,
      66,
      45,
      55,
      77,
      48,
      6,
      162,
      1,
      15,
      65,
      67,
      12,
      9,
      1,
      24,
      38,
      79,
      1,
      8,
      30,
      13,
      20,
      50,
      80,
      62,
      33,
      19,
      12,
      80,
      16,
      27,
      14,
      10,
      23,
      8,
      8,
      29,
      12,
      43,
      31,
      18,
      32,
      74,
      109,
      19,
      45,
      109,
      36,
      6,
      51,
      57,
      29,
      18,
      3,
      39,
      145,
      42,
      69,
      55,
      49,
      148,
      9,
      32,
      64,
      78,
      27,
      74,
      48,
      26,
      17,
      42,
      73,
      180,
      138,
      108,
      119,
      6,
      82,
      10,
      94,
      18,
      92,
      54,
      102,
      262,
      107,
      196,
      134,
      133,
      96,
      332,
      82,
      234,
      193,
      69,
      34,
      45,
      70,
      85,
      113,
      76,
      219,
      212,
      126,
      41,
      160,
      197,
      71,
      70,
      196,
      62,
      206,
      132,
      115,
      137,
      90,
      169,
      66,
      81,
      125,
      113,
      77,
      48,
      192,
      117,
      63,
      95,
      149,
      142,
      81,
      118,
      76,
      97,
      157,
      99,
      118,
      92,
      126,
      152,
      101,
      132,
      98,
      118,
      85,
      145,
      68,
      38,
      96,
      77,
      65,
      111,
      154,
      150,
      50,
      127,
      101,
      128,
      138,
      196,
      41,
      64,
      100,
      105,
      113,
      82,
      177,
      57,
      40,
      74,
      81,
      138,
      121,
      150,
      93,
      129,
      161,
      47,
      73,
      132,
      91,
      123,
      174,
      84,
      147,
      168,
      129,
      190,
      57,
      43,
      105,
      19,
      68,
      76,
      268,
      39,
      87,
      132,
      150,
      104,
      82,
      98,
      16,
      23,
      23,
      70,
      38,
      54,
      128,
      38,
      35,
      112,
      37,
      56,
      87,
      71,
      70,
      113,
      143,
      110,
      29,
      84,
      70,
      90,
      269,
      92,
      153,
      94,
      96,
      68,
      29,
      53,
      44,
      122,
      110,
      129,
      136,
      129,
      146,
      259,
      137,
      381,
      236,
      171,
      24,
      43,
      112,
      49,
      103,
      140,
      123,
      58,
      51,
      135,
      113,
      184,
      53,
      76,
      49,
      115,
      115,
      184,
      111,
      176,
      175,
      51,
      143,
      82,
      119,
      74,
      65,
      92,
      64,
      106,
      166,
      66,
      96,
      105,
      159,
      59,
      94,
      89,
      79,
      221,
      85,
      494,
      942,
      496,
      335,
      419,
      453,
      421,
      294,
      172,
      177,
      53
    ]
  },
  {
    type: "Asside",
    content: "",
    title: "Twitter",
    key: uuidv1()
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        number: 9336,
        prefix: "",
        suffix: "",
        title: "Total",
        unit: "Tweets",
        figuretext:
          "Split between _6,226_ original tweets and _3,110_ retweets",
        infotext:
          "Receiving _24,858_ likes, _582_ retweets and _2,855,600_ impressions"
      },
      {
        key: uuidv1(),
        number: 8,
        noCount: true,
        prefix: "",
        suffix: " AM",
        title: "Busiest Tweeting",
        unit: "Hour",
        figuretext: "",
        infotext:
          "The slowest hour was _3am_, with 96.2% fewer tweets being posted compared to the busiest hour"
      }
    ],
    key: uuidv1()
  },
  {
    type: "BubbleMatrix",
    key: uuidv1(),
    title: "Tweets Per Hour Across Each Week",
    unit: "tweets",
    columns: 24,
    rows: 7,
    color: "var(--accent-color)",
    row_titles: [
      "AM",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "PM"
    ],
    column_titles: [
      {
        title: "Jan",
        columns: 5
      },
      {
        title: "Feb",
        columns: 4
      },
      {
        title: "Mar",
        columns: 4
      },
      {
        title: "Apr",
        columns: 5
      },
      {
        title: "May",
        columns: 4
      },
      {
        title: "Jun",
        columns: 4
      },
      {
        title: "Jul",
        columns: 5
      },
      {
        title: "Aug",
        columns: 4
      },
      {
        title: "Sep",
        columns: 4
      },
      {
        title: "Oct",
        columns: 5
      },
      {
        title: "Nov",
        columns: 4
      },
      {
        title: "Dec",
        columns: 5
      }
    ],
    data: [
      [
        2,
        1,
        0,
        0,
        0,
        1,
        1,
        0,
        0,
        1,
        1,
        0,
        0,
        0,
        2,
        0,
        2,
        0,
        0,
        0,
        0,
        0,
        7,
        0,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
        1,
        0,
        0,
        0,
        4,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      [
        6,
        0,
        0,
        0,
        0,
        5,
        3,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        13,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        0
      ],
      [
        0,
        0,
        0,
        0,
        0,
        1,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        1,
        0,
        0,
        0,
        0,
        19,
        4,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        6,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      [
        0,
        0,
        0,
        0,
        0,
        4,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        16,
        4,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      [
        0,
        0,
        0,
        0,
        0,
        10,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        13,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
      ],
      [
        0,
        0,
        0,
        1,
        0,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        2,
        5,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        5,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        21,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        3,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
        0
      ],
      [
        2,
        0,
        1,
        7,
        10,
        4,
        2,
        1,
        4,
        7,
        8,
        4,
        1,
        8,
        10,
        4,
        2,
        4,
        4,
        4,
        3,
        3,
        14,
        19,
        3,
        1,
        0,
        0,
        1,
        1,
        5,
        0,
        2,
        2,
        0,
        1,
        23,
        6,
        0,
        0,
        0,
        0,
        3,
        4,
        8,
        4,
        6,
        14,
        2,
        12,
        11,
        5,
        2
      ],
      [
        7,
        4,
        13,
        9,
        4,
        10,
        2,
        1,
        14,
        5,
        12,
        5,
        14,
        14,
        12,
        7,
        13,
        10,
        14,
        3,
        11,
        5,
        12,
        16,
        13,
        3,
        13,
        5,
        10,
        4,
        5,
        3,
        22,
        4,
        17,
        10,
        34,
        7,
        8,
        3,
        4,
        13,
        6,
        18,
        15,
        7,
        6,
        16,
        19,
        11,
        8,
        4,
        0
      ],
      [
        5,
        4,
        8,
        10,
        16,
        4,
        4,
        7,
        10,
        8,
        14,
        7,
        11,
        19,
        27,
        5,
        9,
        5,
        5,
        17,
        7,
        2,
        30,
        11,
        2,
        5,
        15,
        20,
        7,
        9,
        8,
        11,
        8,
        15,
        2,
        7,
        21,
        25,
        17,
        9,
        3,
        12,
        6,
        4,
        23,
        6,
        8,
        16,
        6,
        5,
        12,
        2,
        2
      ],
      [
        7,
        3,
        7,
        9,
        9,
        13,
        12,
        9,
        7,
        13,
        19,
        25,
        8,
        6,
        5,
        7,
        22,
        3,
        4,
        22,
        15,
        3,
        22,
        11,
        3,
        6,
        18,
        8,
        8,
        5,
        4,
        8,
        8,
        18,
        11,
        9,
        17,
        11,
        5,
        1,
        3,
        11,
        12,
        13,
        7,
        6,
        4,
        8,
        12,
        8,
        11,
        8,
        2
      ],
      [
        4,
        1,
        11,
        8,
        10,
        23,
        11,
        8,
        8,
        6,
        16,
        9,
        8,
        3,
        9,
        4,
        9,
        6,
        3,
        8,
        4,
        8,
        17,
        9,
        6,
        3,
        10,
        3,
        1,
        5,
        14,
        6,
        10,
        5,
        15,
        5,
        15,
        16,
        11,
        1,
        3,
        4,
        13,
        5,
        3,
        5,
        13,
        3,
        22,
        5,
        5,
        12,
        12
      ],
      [
        4,
        0,
        6,
        6,
        5,
        26,
        15,
        5,
        7,
        16,
        11,
        10,
        3,
        5,
        11,
        6,
        1,
        3,
        6,
        3,
        7,
        7,
        10,
        11,
        7,
        7,
        14,
        6,
        5,
        8,
        1,
        6,
        17,
        30,
        14,
        2,
        2,
        18,
        3,
        5,
        4,
        2,
        3,
        6,
        2,
        7,
        6,
        6,
        8,
        13,
        3,
        4,
        2
      ],
      [
        2,
        3,
        0,
        2,
        17,
        9,
        3,
        6,
        3,
        5,
        17,
        5,
        3,
        5,
        4,
        7,
        1,
        4,
        7,
        4,
        12,
        0,
        1,
        13,
        6,
        2,
        1,
        3,
        7,
        5,
        4,
        8,
        9,
        30,
        4,
        1,
        6,
        12,
        7,
        6,
        2,
        7,
        6,
        3,
        6,
        4,
        5,
        4,
        12,
        4,
        6,
        6,
        3
      ],
      [
        0,
        6,
        7,
        3,
        9,
        4,
        15,
        20,
        8,
        6,
        11,
        10,
        5,
        13,
        9,
        1,
        11,
        9,
        5,
        8,
        7,
        5,
        1,
        8,
        8,
        4,
        3,
        2,
        8,
        3,
        9,
        7,
        5,
        48,
        6,
        4,
        6,
        13,
        6,
        5,
        2,
        2,
        3,
        5,
        16,
        5,
        11,
        7,
        6,
        2,
        2,
        5,
        2
      ],
      [
        2,
        3,
        6,
        5,
        17,
        3,
        10,
        11,
        5,
        4,
        14,
        2,
        3,
        11,
        0,
        1,
        5,
        4,
        10,
        4,
        4,
        4,
        7,
        12,
        4,
        12,
        3,
        7,
        6,
        4,
        6,
        8,
        11,
        5,
        6,
        6,
        7,
        9,
        2,
        2,
        2,
        5,
        9,
        4,
        6,
        8,
        3,
        6,
        6,
        5,
        1,
        6,
        2
      ],
      [
        0,
        6,
        3,
        4,
        13,
        2,
        7,
        2,
        3,
        4,
        8,
        9,
        3,
        10,
        6,
        2,
        10,
        10,
        4,
        2,
        7,
        3,
        9,
        27,
        3,
        4,
        1,
        5,
        7,
        7,
        0,
        4,
        12,
        9,
        9,
        5,
        2,
        10,
        2,
        0,
        5,
        3,
        9,
        5,
        4,
        8,
        5,
        5,
        13,
        3,
        3,
        6,
        9
      ],
      [
        5,
        6,
        5,
        7,
        7,
        7,
        14,
        1,
        5,
        3,
        4,
        4,
        4,
        8,
        6,
        12,
        6,
        10,
        3,
        3,
        2,
        8,
        6,
        5,
        0,
        8,
        5,
        2,
        4,
        4,
        3,
        1,
        1,
        11,
        4,
        3,
        3,
        8,
        8,
        1,
        5,
        2,
        4,
        11,
        5,
        5,
        7,
        8,
        15,
        3,
        6,
        3,
        2
      ],
      [
        8,
        3,
        7,
        2,
        9,
        1,
        5,
        5,
        3,
        8,
        11,
        12,
        7,
        14,
        16,
        4,
        5,
        27,
        2,
        3,
        5,
        7,
        4,
        5,
        5,
        2,
        6,
        3,
        0,
        2,
        7,
        13,
        6,
        6,
        7,
        12,
        23,
        11,
        9,
        1,
        1,
        5,
        9,
        6,
        15,
        0,
        5,
        10,
        3,
        6,
        7,
        4,
        3
      ],
      [
        4,
        2,
        7,
        5,
        18,
        0,
        4,
        6,
        1,
        4,
        3,
        4,
        2,
        14,
        21,
        10,
        1,
        4,
        3,
        6,
        2,
        8,
        0,
        3,
        7,
        0,
        5,
        2,
        0,
        1,
        3,
        2,
        4,
        5,
        7,
        5,
        5,
        7,
        7,
        3,
        3,
        8,
        14,
        5,
        21,
        4,
        10,
        6,
        8,
        5,
        5,
        2,
        4
      ],
      [
        9,
        3,
        6,
        9,
        9,
        0,
        6,
        1,
        1,
        7,
        8,
        1,
        4,
        17,
        13,
        4,
        7,
        2,
        3,
        12,
        0,
        1,
        0,
        2,
        7,
        10,
        4,
        5,
        2,
        1,
        1,
        5,
        2,
        8,
        7,
        11,
        10,
        11,
        11,
        4,
        5,
        8,
        14,
        3,
        7,
        5,
        7,
        4,
        12,
        4,
        6,
        3,
        3
      ],
      [
        3,
        10,
        9,
        6,
        7,
        0,
        1,
        4,
        7,
        9,
        12,
        2,
        3,
        16,
        8,
        10,
        7,
        2,
        10,
        6,
        3,
        4,
        0,
        4,
        9,
        3,
        2,
        8,
        6,
        6,
        2,
        2,
        8,
        7,
        13,
        3,
        17,
        14,
        9,
        4,
        4,
        7,
        13,
        4,
        3,
        4,
        7,
        4,
        6,
        4,
        4,
        5,
        3
      ],
      [
        11,
        3,
        7,
        10,
        5,
        0,
        2,
        3,
        1,
        3,
        10,
        3,
        3,
        5,
        6,
        4,
        2,
        8,
        3,
        6,
        8,
        3,
        0,
        3,
        9,
        4,
        2,
        7,
        1,
        0,
        7,
        5,
        5,
        4,
        10,
        2,
        2,
        2,
        8,
        0,
        2,
        3,
        14,
        3,
        2,
        5,
        5,
        3,
        7,
        7,
        5,
        1,
        5
      ],
      [
        1,
        5,
        5,
        7,
        17,
        0,
        0,
        0,
        4,
        3,
        10,
        2,
        3,
        4,
        15,
        6,
        4,
        2,
        1,
        4,
        2,
        2,
        1,
        1,
        6,
        3,
        5,
        5,
        3,
        4,
        7,
        1,
        4,
        5,
        6,
        5,
        12,
        3,
        2,
        1,
        5,
        3,
        5,
        0,
        1,
        2,
        0,
        5,
        9,
        1,
        4,
        3,
        1
      ],
      [
        2,
        0,
        4,
        2,
        9,
        0,
        0,
        0,
        5,
        6,
        1,
        0,
        0,
        5,
        7,
        0,
        1,
        0,
        1,
        2,
        0,
        0,
        7,
        0,
        2,
        0,
        3,
        4,
        0,
        0,
        0,
        0,
        2,
        1,
        2,
        4,
        11,
        3,
        3,
        0,
        0,
        5,
        1,
        0,
        5,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        1
      ]
    ]
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        noCount: true,
        number: 6,
        prefix: "Dec ",
        suffix: "",
        title: "Most Daily",
        unit: "Tweets",
        figuretext:
          "With _138_ tweets, a _552%_ increase over the daily average",
        infotext: ""
      },
      {
        key: uuidv1(),
        number: 27.01,
        prefix: "",
        suffix: "",
        title: "Longest Gap",
        unit: "Hours",
        figuretext: "",
        infotext: "Beginning September 29 at 5:17 PM"
      }
    ],
    key: uuidv1()
  },
  {
    type: "Asside",
    content: "I acquired a new Fuji X camera this year just in time for WWDC which has really increased the number of photos that I snpped. Below is a couple hundred photos to summarise my year (in random order.)",
    title: "Photos",
    key: uuidv1()
  },
  {
    type: "NumberGroup",
    collection: [
      {
        key: uuidv1(),
        number: 13264,
        prefix: "",
        suffix: "",
        title: "Photos taken",
        unit: "Total",
        figuretext: "A _46%_ increase over 2017.",
        infotext: ""
      }
    ],
    key: uuidv1()
  },
  {
    type: "Gallery",
    thumbs: [
        'THUMB_00000.jpeg',
        'THUMB_00001.jpeg',
        'THUMB_00002.jpeg',
        'THUMB_00003.jpeg',
        'THUMB_00004.jpeg',
        'THUMB_00005.jpeg',
        'THUMB_00006.jpeg',
        'THUMB_00007.jpeg',
        'THUMB_00008.jpeg',
        'THUMB_00009.jpeg',
        'THUMB_00010.jpeg',
        'THUMB_00011.jpeg',
        'THUMB_00012.jpeg',
        'THUMB_00013.jpeg',
        'THUMB_00014.jpeg',
        'THUMB_00015.jpeg',
        'THUMB_00016.jpg',
        'THUMB_00017.jpeg',
        'THUMB_00018.jpeg',
        'THUMB_00019.jpeg',
        'THUMB_00020.jpeg',
        'THUMB_00021.jpeg',
        'THUMB_00022.jpeg',
        'THUMB_00023.jpeg',
        'THUMB_00024.jpeg',
        'THUMB_00025.jpeg',
        'THUMB_00026.jpeg',
        'THUMB_00027.jpeg',
        'THUMB_00028.jpeg',
        'THUMB_00029.jpeg',
        'THUMB_00030.jpeg',
        'THUMB_00031.jpeg',
        'THUMB_00032.jpeg',
        'THUMB_00033.jpeg',
        'THUMB_00034.jpeg',
        'THUMB_00035.jpeg',
        'THUMB_00036.jpeg',
        'THUMB_00037.jpeg',
        'THUMB_00038.jpeg',
        'THUMB_00039.jpeg',
        'THUMB_00040.jpeg',
        'THUMB_00041.jpeg',
        'THUMB_00042.jpeg',
        'THUMB_00043.jpeg',
        'THUMB_00044.jpeg',
        'THUMB_00045.jpeg',
        'THUMB_00046.jpeg',
        'THUMB_00047.jpeg',
        'THUMB_00048.jpeg',
        'THUMB_00049.jpeg',
        'THUMB_00050.jpeg',
        'THUMB_00051.jpeg',
        'THUMB_00052.jpeg',
        'THUMB_00053.jpeg',
        'THUMB_00054.jpeg',
        'THUMB_00055.jpeg',
        'THUMB_00056.jpeg',
        'THUMB_00057.jpeg',
        'THUMB_00058.jpeg',
        'THUMB_00059.jpeg',
        'THUMB_00060.jpeg',
        'THUMB_00061.jpeg',
        'THUMB_00062.jpeg',
        'THUMB_00063.jpeg',
        'THUMB_00064.jpeg',
        'THUMB_00065.jpeg',
        'THUMB_00066.jpeg',
        'THUMB_00067.jpeg',
        'THUMB_00068.jpeg',
        'THUMB_00069.jpeg',
        'THUMB_00070.jpeg',
        'THUMB_00071.jpeg',
        'THUMB_00072.jpeg',
        'THUMB_00073.jpeg',
        'THUMB_00074.jpeg',
        'THUMB_00075.jpeg',
        'THUMB_00076.jpeg',
        'THUMB_00077.jpeg',
        'THUMB_00078.jpeg',
        'THUMB_00079.jpeg',
        'THUMB_00080.jpeg',
        'THUMB_00081.jpeg',
        'THUMB_00082.jpeg',
        'THUMB_00083.jpeg',
        'THUMB_00084.jpeg',
        'THUMB_00085.jpeg',
        'THUMB_00086.jpeg',
        'THUMB_00087.jpeg',
        'THUMB_00088.jpeg',
        'THUMB_00089.jpeg',
        'THUMB_00090.jpeg',
        'THUMB_00091.jpeg',
        'THUMB_00092.jpeg',
        'THUMB_00093.jpeg',
        'THUMB_00094.jpeg',
        'THUMB_00095.jpeg',
        'THUMB_00096.jpeg',
        'THUMB_00097.jpeg',
        'THUMB_00098.jpeg',
        'THUMB_00099.jpeg',
        'THUMB_00100.jpeg',
        'THUMB_00101.jpeg',
        'THUMB_00102.jpeg',
        'THUMB_00103.jpeg',
        'THUMB_00104.jpeg',
        'THUMB_00105.jpeg',
        'THUMB_00106.jpeg',
        'THUMB_00107.jpeg',
        'THUMB_00108.jpeg',
        'THUMB_00109.jpeg',
        'THUMB_00110.jpeg',
        'THUMB_00111.jpeg',
        'THUMB_00112.jpeg',
        'THUMB_00113.jpeg',
        'THUMB_00114.jpeg',
        'THUMB_00115.jpeg',
        'THUMB_00116.jpeg',
        'THUMB_00117.jpeg',
        'THUMB_00118.jpeg',
        'THUMB_00119.jpeg',
        'THUMB_00120.jpeg',
        'THUMB_00121.jpeg',
        'THUMB_00122.jpeg',
        'THUMB_00123.jpeg',
        'THUMB_00124.jpeg',
        'THUMB_00125.jpeg',
        'THUMB_00126.jpeg',
        'THUMB_00127.jpeg',
        'THUMB_00128.jpeg',
        'THUMB_00129.jpeg',
        'THUMB_00130.jpeg',
        'THUMB_00131.jpeg',
        'THUMB_00132.jpeg',
        'THUMB_00133.jpeg',
        'THUMB_00134.jpeg',
        'THUMB_00135.jpeg',
        'THUMB_00136.jpeg',
        'THUMB_00137.jpeg',
        'THUMB_00138.jpeg',
        'THUMB_00139.jpeg',
        'THUMB_00140.jpeg',
        'THUMB_00141.jpeg',
        'THUMB_00142.jpeg',
        'THUMB_00143.jpeg'
    ],
    key: uuidv1()
  },
  {
      type: "Footer",
      key: "Footer"
  }
];
