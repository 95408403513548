import React from 'react'; // required in order to use JSX

export const FullImage = (props) => {

  return (
      <img className="img-fullwidth" src={ props.src } alt={ props.alt }/>
  );

};

export default FullImage;

